import _ from 'lodash';
import { get } from 'lodash';

const findElsePush = (arr, id) => {
  //changes arr by ref
  const index = arr.findIndex((i) => i === id);
  if (index === -1) {
    arr.push(id);
  }
};
const findNPop = (arr, id) => {
  //changes arr by ref
  const index = arr.findIndex((i) => i === id);
  if (index > -1) {
    arr.splice(index, 1);
  }
};
const updatePacks = (packs = {}, packId, packChAccess, packChExcept, COUNTER_ACCESS, COUNTER_EXPECT) => {
  packs = {
    ...packs,
    [packId]: {
      ...get(packs, [packId], {}),
      [COUNTER_ACCESS]: [...packChAccess],
      [COUNTER_EXPECT]: [...packChExcept],
    },
  };
  return { ...packs };
};


export const PACK_SERVICES_KEYS = {
  PRIMARY_KEY: 'packs',
  COUNTER_KEY: 'services',
  PRIMARY_ACCESS: 'services',
  PRIMARY_EXCEPT: 'service_except',
  COUNTER_ACCESS: 'packs',
  COUNTER_EXPECT: 'except',
};


export const PACK_CLASSES_KEYS = {
    PRIMARY_KEY: 'packs',
    COUNTER_KEY: 'classes',
    PRIMARY_ACCESS: 'classes',
    PRIMARY_EXCEPT: 'except',
    COUNTER_ACCESS: 'packs',
    COUNTER_EXPECT: 'except',
  };


export const getAccess = (_id, currentAccess, accessDoc, KEYS, access_extra_info) => {
  const PRIMARY_KEY = KEYS.PRIMARY_KEY;
  const COUNTER_KEY = KEYS.COUNTER_KEY;
  const PRIMARY_EXCEPT = KEYS.PRIMARY_EXCEPT;
  const PRIMARY_ACCESS = KEYS.PRIMARY_ACCESS;
  const COUNTER_ACCESS = KEYS.COUNTER_ACCESS;
  const COUNTER_EXPECT = KEYS.COUNTER_EXPECT;

  if (!PRIMARY_KEY || !COUNTER_KEY || !PRIMARY_EXCEPT || !PRIMARY_ACCESS || !COUNTER_ACCESS || !COUNTER_EXPECT) {
    console.log(' >>>> some of the keys not available', KEYS);
    return accessDoc;
  }

  //main packages
  let primary = get(accessDoc, [PRIMARY_KEY], {});
  let counter = get(accessDoc, [COUNTER_KEY], {});

  //entities with ALL access
  const primaryWithAllAccess = get(primary, ['all', COUNTER_KEY], []); //1
  const counterWithAllAccess = get(counter, ['all', PRIMARY_KEY], []); //2

  //previous primary access state
  const primaryPreviousAccess = get(accessDoc, [PRIMARY_KEY, _id, PRIMARY_ACCESS], []); //3
  const primaryPreviousExcept = get(accessDoc, [PRIMARY_KEY, _id, PRIMARY_EXCEPT], []); //4

  //primary current access state
  const primaryCurrentAccess = get(currentAccess, ['access'], []); //5
  const primaryCurrExcept = get(currentAccess, ['except'], []); //6

  //questions
  const thisPrimaryHasAllAccess = primaryCurrentAccess.includes('all'); //7

  //Case 1:
  if (thisPrimaryHasAllAccess) {
    findElsePush(primaryWithAllAccess, _id);
  } else {
    findNPop(primaryWithAllAccess, _id);
  }
  //update challenge access
  primary = {
    ...primary,
    [_id]: {
      ...get(primary, [_id], {}),
      ...(access_extra_info || {}),
      [PRIMARY_ACCESS]: [...primaryCurrentAccess],
      [PRIMARY_EXCEPT]: [...primaryCurrExcept],
    },
    all: {
      ...get(primary, 'all', {}),
      [COUNTER_KEY]: [...primaryWithAllAccess],
    },
  };

  //Case 2:
  const primaryCurrAccessIds = primaryCurrentAccess.filter((i) => !["all", "free"].includes(i));
  primaryCurrAccessIds.forEach((p) => {
    const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
    const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
    const thisCounterHasAllAccess = counterMainAccess.includes('all');

    if (thisCounterHasAllAccess) {
      findNPop(counterMainExcept, _id);
    } else {
      findElsePush(counterMainAccess, _id);
    }
    //update challenge access
    counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
  });

  //Case 3:
  if (!!primaryCurrAccessIds.length) {
    counterWithAllAccess.forEach((p) => {
      if (primaryCurrentAccess.includes(p)) return;
      const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
      const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
      findElsePush(counterMainExcept, _id);
      //update challenge access
      counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
    });
  }

  //Case 4:
  if (thisPrimaryHasAllAccess && !primaryCurrExcept.length) {
    counterWithAllAccess.forEach((p) => {
      const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
      const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
      findNPop(counterMainExcept, _id);
      //update challenge access
      counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
    });
  }

  //Case 5:
  const primaryRemovedAccess = primaryPreviousAccess.filter((i) => !["all", "free"].includes(i) && !primaryCurrentAccess.includes(i));
  primaryRemovedAccess.forEach((p) => {
    const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
    const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
    const thisPackHasAllAccess = counterMainAccess.includes('all');
    if (thisPackHasAllAccess) {
      //TODO: do nothing?
    } else {
      findNPop(counterMainAccess, _id);
    }

    //update challenge access
    counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
  });

  //Case 6:
  primaryCurrExcept.forEach((p) => {
    const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
    const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
    const thisPackHasAllAccess = counterMainAccess.includes('all');
    if (thisPackHasAllAccess) {
      findElsePush(counterMainExcept, _id);
    } else {
      findNPop(counterMainAccess, _id);
    }
    //update challenge access
    counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
  });

  //Case 7:
  const primaryRemovedExcept = primaryPreviousExcept.filter((i) => !primaryCurrExcept.includes(i));
  primaryRemovedExcept.forEach((p) => {
    const counterMainAccess = get(counter, [p, COUNTER_ACCESS], []);
    const counterMainExcept = get(counter, [p, COUNTER_EXPECT], []);
    const thisPackHasAllAccess = counterMainAccess.includes('all');
    if (thisPackHasAllAccess) {
      findNPop(counterMainExcept, _id);
    }
    //update challenge access
    counter = { ...updatePacks(counter, p, counterMainAccess, counterMainExcept, COUNTER_ACCESS, COUNTER_EXPECT) };
  });

  return {
    ...accessDoc,
    [PRIMARY_KEY]: primary,
    [COUNTER_KEY]: counter,
  };
};

import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  Divider,
} from '@material-ui/core';
import _ from 'lodash';
import { usePicker } from 'fitbud/hooks/form';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import GcAccess, { GC_ACCESS_TYPES_WITHOUT_NONE } from './gcAccess';
import ServiceAccess from './servicesAccess';
import { useSnackbar } from 'notistack';
import { getAccessType } from './regularCalling';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const DEFAULT_VIDEO_CALL = {
  includes_calls: true,
  num_sessions: 1,
  duration: 15,
  frequency: 'week',
};

const AddOnCalling = (props) => {
  const { fnRef, access: accessState, compareDirty, data = {}, id, memoizeGcs, setDirty, gcAccess, isNew, serviceAccess } = props;
  const {comp} = useContext(FirebaseAuthContext)
  const company = comp ? comp.data() : {};
  const [vid_call, setVideoCall] = useState(data.vid_call || DEFAULT_VIDEO_CALL);
  const [add_on_type, setVideoPackType] = usePicker(data.add_on_type || 'one_to_one');
  const [gc_access_type, setGcAccessType] = usePicker(getAccessType(gcAccess, 'selected'));
  const duration = _.get(vid_call, 'duration', 15);
  const isOneToOneCall = add_on_type === 'one_to_one';
  const [selectedGcs, setSelectedGcs] = useState(_.get(gcAccess, 'access', []));
  const [exceptGcs, setExceptGcs] = useState(_.get(gcAccess, 'except', []));
  const [selectedServices, setSelectedServices] = useState(_.get(serviceAccess, 'access', [])); //local memory..
  const [exceptServices, setExServices] = useState(_.get(serviceAccess, 'except', []));
  const [service_access_type, setServiceAccessType] = useState(getAccessType(serviceAccess));
  const { enqueueSnackbar } = useSnackbar();
  const isExceptAll = gc_access_type === 'except_all';
  const isSpecific = gc_access_type === 'selected';
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const isExceptServices = service_access_type === "except_all";
  const isSpecificServices = service_access_type === "selected";


  const classesWithAllAccess = useMemo(() => {
    return _.get(accessState, 'classes.all.packs', []);
  }, [accessState]);

  useEffect(() => {
    //this is required to set on first time, if gcAccess is empty then set it accordingly access_type.
    if (selectedGcs && selectedGcs.length) return;
    setAccessOnAccessType(gc_access_type);
  }, []);

  useEffect(() => {
    if (compareDirty) compareDirty({ vid_call, add_on_type });
    if (!_.isEqual(selectedGcs, _.get(accessState, `packs.${id}.classes`, []))) setDirty(true);
    if (!_.isEqual(exceptGcs, _.get(accessState, `packs.${id}.except`, []))) setDirty(true);
    if (!_.isEqual(selectedServices, _.get(accessState, `packs.${id}.services`, []))) setDirty(true);
    if (!_.isEqual(exceptServices, _.get(accessState, `packs.${id}.service_except`, []))) setDirty(true);
    fnRef.current = (setState, setGcAccess, setServicesAccess) => {
      if (!setState) return false;
      if (add_on_type === 'group_class' && gc_access_type === 'none') {
        enqueueSnackbar('access control cannot be none', { variant: 'error' });
        return false;
      }
      if (add_on_type === 'group_class' && gc_access_type === 'selected' && !selectedGcs.length) {
        enqueueSnackbar('Select at lease one group class in case of selected mode', { variant: 'error' });
        return false;
      }
      if (add_on_type === 'one_to_one' && service_access_type === 'none') {
        enqueueSnackbar('access control of services cannot be none', { variant: 'error' });
        return false;
      }
      if (add_on_type === 'one_to_one' && service_access_type === 'selected' && !selectedGcs.length) {
        enqueueSnackbar('Select at lease one Services in case of selected mode', { variant: 'error' });
        return false;
      }
      setState((curr) => ({
        ...curr,
        vid_call,
        add_on_type,
      }));
      setGcAccess({ access: selectedGcs, except: exceptGcs });
      setServicesAccess && setServicesAccess({access : selectedServices, except: exceptServices})
      return true;
    };
  }, [fnRef, compareDirty, setDirty, gcAccess, vid_call, add_on_type, selectedGcs, gc_access_type, exceptGcs, selectedServices, service_access_type, exceptServices]);

  const onChangeVideoPack = (e) => {
    const value = e.target.value;
    if (value === 'one_to_one') setVideoCall((s) => ({ ...s, includes_calls: true }));
    else setVideoCall((s) => ({ ...s, includes_calls: false }));
    setVideoPackType(e);
  };
  const onChangeDuration = (e) => {
    const value = e.target.value;
    setVideoCall((s) => ({ ...s, duration: value }));
  };

  const setAccessOnAccessType = (value) => {
    if (value === 'all' || value === 'except_all') {
      setSelectedGcs(['all']);
      setExceptGcs([]);
    }
    if (value === 'selected') {
      setSelectedGcs([...classesWithAllAccess]);
      setExceptGcs([]);
    }
    if (value === 'none') {
      setSelectedGcs([]);
      setExceptGcs([]);
    }
  };

  const setServicesAccessOnAccessType = (type) =>{
    if(type === "all"){
      setSelectedServices(["all"]);
      setExServices([]);
    }else if(type === "selected"){
      setSelectedServices([]);
      setExServices([]);
    }else if(type === "except_all"){
      setSelectedServices(["all"]);
      setExServices([]);
    }else if(type === "none"){
      setSelectedServices([]);
      setExServices([]);
    }
  };


  const onChangeAccessControl = (e) => {
    const value = e.target.value;
    setGcAccessType(value);
    setAccessOnAccessType(value);
  };

  const onChangeServiceAccessType = (e) =>{
    const value = e.target.value;
    setServiceAccessType(value);
    setServicesAccessOnAccessType(value);

  }

  const handleSetValues = (values) => {
    if (isExceptAll) setExceptGcs(values);
    else if (isSpecific) setSelectedGcs(values);
  };

  const handleServiceValues = (values) =>{
    if (isExceptServices) setExServices(values);
    else if (isSpecificServices) setSelectedServices(values);
  }

  return (
    <div className={clsx('p-20 w-100')}>
      {isNew && isGroupClass && (
        <div>
          <RadioGroup
            className={clsx('d-flex flex-row')}
            style={{ marginTop: '-4px', marginBottom: '16px' }}
            value={add_on_type}
            onChange={onChangeVideoPack}>
            <FormControlLabel
              value={'one_to_one'}
              control={<Radio color="primary" />}
              classes={{ label: 'font_16_500' }}
              className="mr-30 mb-0"
              label="Appointments Pack"
              disabled={!isNew}
            />
            <FormControlLabel
              value={'group_class'}
              control={<Radio color="primary" />}
              classes={{ label: 'font_16_500' }}
              className="mb-0"
              label={'Group Classes Pack'}
              disabled={!isNew}
            />
          </RadioGroup>
          <Divider className="mb-25 mt-16 ml-n20 mr-n20" />
        </div>
      )}
      <div className="p-15 info-box-blue mb-20">
        <Typography className="font_14_500">
          {isOneToOneCall
            ? 'Set appointment duration for the clients on this payment plan. The quota, however, is defined by the number of calls in the pricing option.'
            : 'The clients on this plan can book any of the group classes as specified below. The quota, however, is defined by the number of calls in the pricing option.'}
        </Typography>
      </div>
      {!!isOneToOneCall && (
        <div>
          <ServiceAccess
            accessType={service_access_type}
            setAccessType={onChangeServiceAccessType}
            values={service_access_type === 'selected' ? selectedServices : exceptServices}
            setValues={handleServiceValues}
            memoizeGcs={memoizeGcs}
          />
        </div>
      )}
      {!isOneToOneCall && (
        <div>
          <GcAccess
            accessType={gc_access_type}
            setAccessType={onChangeAccessControl}
            values={gc_access_type === 'selected' ? selectedGcs : exceptGcs}
            setValues={handleSetValues}
            memoizeGcs={memoizeGcs}
            ACCESS_OPTIONS={GC_ACCESS_TYPES_WITHOUT_NONE}
          />
        </div>
      )}
    </div>
  );
};

export default AddOnCalling;

export const SERVICE_FILTER_CONFIG = [
  {
    label: 'Service Status',
    subType: 'status',
    subOptions: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  {
    label: 'Mode',
    subType: 'mode',
    subOptions: [
      { label: 'In Person', value: 'offline' },
      { label: 'Online', value: 'online' },
    ],
  },
];

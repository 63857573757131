import _ from 'lodash';
import React, { useState, useMemo, useContext } from 'react';
import {
  FormControlLabel, Switch, Grid,
  IconButton, Paper, Typography, Tooltip,
  Checkbox, Button, Divider,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from 'fitbud/icons/detailEdit';
import Confirmation from 'fitbud/components/confirmationDialog';
import { useEventData } from 'fitbud/hooks/form';
import ReorderIcon from '@material-ui/icons/SwapVert';
import ReorderDialog from 'fitbud/components/reorderDialog';
import { ClrdButton } from 'fitbud/components/form-fields';
import {
  PriceAvatar, RenderPlanChip,
  getActiveStatus
} from '../helper';
import Editor from '../editors';
import {makeStyles} from "@material-ui/core/styles"
import {useSnackbar} from "notistack";
import clsx from "clsx";
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';
import {REGULAR_STEPS, ADD_ON_STEPS} from "../editors/index";
import timeIcon from "fitbud/images/plans/time.svg";
import videoIcon from "fitbud/images/plans/video.svg";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const TXT_ACTIVATE = "Activating a price will make it immediately available for client purchase. Do you want to continue?";
const TXT_DEACTIVATE = "This price will be hidden for further client purchases. Existing client purchases will not be affected. Do you want to continue?";
const TXT = {
  week: '/ Week',
  month: '/ Month',
  plan: 'Total',
};

const DetailTab = (props) => {
  const { isAdmin, id, onSave, data, hasAppleIAP, hasPlayIAP, hasVidCall, planWebUrl, access, memoizeGcs, updateAccess } = props;
  const { price_opts, active, hidden, type, add_on_type, activeV2 } = data || {};
  const hasActive = activeV2 === undefined ? active : activeV2;
  const [edit, setEdit] = useState({});
  const [reorder, setReorder] = useState(null);
  const [confirm, setConfirm] = useEventData();
  const isAddOn = type === "add_on";
  const priceOpts = useMemo(() => {
    const web = [], ios = [], play = [];
    if (!price_opts || !price_opts.length)
      return {web, ios, play};
    price_opts.forEach(x => {
      const {iap_id, play_product_id, method_ios} = x;
      if (!!iap_id && method_ios === 'iap')
        ios.push(x);
      else if (!!play_product_id)
        play.push(x);
      else
        web.push(x);
    });
    return {web, ios, play};
  }, [price_opts]);
  const STEPS = useMemo(()=>{
    if(isAddOn) return ADD_ON_STEPS;
    else return REGULAR_STEPS;
  },[isAddOn])

  const reorderData = useMemo(() => {
    if (!reorder || !priceOpts || !priceOpts[reorder])
      return null;
    if (reorder === 'web')
      return priceOpts.web.map(x => ({id: x.id, name: (
        x.mode === 'one_time' ?
        `${x.duration} ${_.capitalize(x.duration_type)}` :
        `${_.capitalize(x.subs_duration_type)}ly Subscription`
      )}));
    if (reorder === 'ios')
      return priceOpts.ios.map(x => ({id: x.id, name: (
        x.mode === 'one_time' ?
        `${x.iap_id.toUpperCase()} - ${x.duration} ${_.capitalize(x.duration_type)}` :
        `${x.iap_id.toUpperCase()} - Subscription`
      )}));
    if (reorder === 'play')
      return priceOpts.play.map(x => ({id: x.id, name: (
        x.mode === 'one_time' ?
        `${x.play_product_id.toLowerCase()} - ${x.duration} ${_.capitalize(x.duration_type)}` :
        `${x.play_product_id.toLowerCase()} - Subscription`
      )}));
  }, [reorder, priceOpts]);

  const onEditClick = (e) => {
    if (!e || !e.currentTarget) return setEdit({});
    const data = e.currentTarget.dataset;
    if (!data || !data.mode) return setEdit({});
    const out = {...data, mode: Number(data.mode)};
    setEdit(out);
  };

  const toggleActive = () => {
    if (!confirm || !confirm.price) return;
    const index = _.findIndex(price_opts, ['id', confirm.price]);
    setConfirm();
    if (index < 0) return;
    const update = [...price_opts];
    const opt = update[index];
    if (opt.iap_id && opt.method_ios === 'iap') {
      opt.activeV2 = false;
      opt.active_iosV2 = !getActiveStatus(opt, "active_ios");
      opt.active_androidV2 = false;
    } else if (opt.play_product_id) {
      opt.activeV2 = false;
      opt.active_iosV2 = false;
      opt.active_androidV2 = !getActiveStatus(opt, "active_android");
    } else {
      opt.activeV2 = !getActiveStatus(opt, "active");
      opt.active_iosV2 = getActiveStatus(opt, "active");
      opt.active_androidV2 = getActiveStatus(opt, "active");
    }
    if(!isAddOn){
      opt.active = opt.activeV2;
      opt.active_ios = opt.active_iosV2;
      opt.active_android = opt.active_androidV2;
    }
    update[index] = opt;
    const changes = { price_opts: update };
    const anyActive = _.findIndex(update, opt => {
      if (opt.iap_id && opt.method_ios === 'iap')
        return getActiveStatus(opt, "active_ios");
      else if (opt.play_product_id)
        return getActiveStatus(opt, "active_android");
      else
        return getActiveStatus(opt, "active");
    });
    if (anyActive < 0 && getActiveStatus(data, "active")) {
      changes.activeV2 = false;
      changes.active_iosV2 = false;
      if(!isAddOn){
        changes.active = changes.activeV2;
        changes.active_ios = changes.active_iosV2;
      }
    }
    if (props.saveChanges) props.saveChanges(changes);
  }

  const reorderWeb = () => setReorder('web');
  const reorderIOS = () => setReorder('ios');
  const reorderPlay = () => setReorder('play');
  const closeReorder = () => setReorder(null);
  const getVideoCallTitle = () =>{
    if (isAddOn) {
      if (add_on_type === 'group_class') return 'Group Classes';
      else return 'Appointments';
    } else return 'Appointments';
  }

  const onReorder = prices => {
    const pin = prices.map(({id}) => _.find(price_opts, ['id', id]));
    let out = [];
    switch (reorder) {
      case 'web':
        out = [...pin, ...priceOpts.ios, ...priceOpts.play];
        break;
      case 'ios':
        out = [...priceOpts.web, ...pin, ...priceOpts.play];
        break;
      case 'play':
        out = [...priceOpts.web, ...priceOpts.ios, ...pin];
        break;
      default:
        return;
    }
    if (props.saveChanges) props.saveChanges({ price_opts: out });
  };

  const handlePrivatePurchase = (e)=>{
    let checked = e.target.checked;
    if (props.saveChanges) props.saveChanges({ hidden:checked })
  }

  return (
    <Grid container className='fpx-25 fpy-25' alignItems='stretch'>
      {!hasActive && <PrivatePurchaseAlert planWebUrl={planWebUrl} hidden={hidden} handleChange={handlePrivatePurchase} />}
      <DetailItem hasItem={priceOpts.web.length > 0}>
        <DetailTitle title='Pricing Info'>
          <RenderPlanChip web pricesIOS={priceOpts.ios} pricesPlay={priceOpts.play} vidCallData={data.vid_call} />
          <div className='flex-grow-1'/>
          <ClrdButton color='primary' className='f-xmedium px-2'
            onClick={onEditClick} data-mode={STEPS.PRICING}
            variant={priceOpts.web.length < 1 ? 'contained' : 'text'}>
            {priceOpts.web.length < 1 ? '+ Add Price' : '+ Add More'}
          </ClrdButton>
          {priceOpts.web.length > 0 && (<Tooltip enterDelay={0}
            title='Reorder - Pricing Options will show in the same order you decide.'>
            <IconButton disabled={priceOpts.web.length < 2} onClick={reorderWeb}><ReorderIcon/></IconButton>
          </Tooltip>)}
        </DetailTitle>
        {priceOpts.web.map((x, i) => (<Price STEPS={STEPS} type={type} add_on_type={add_on_type} key={x.id} price={x} onEditClick={onEditClick}
          toggleActive={setConfirm} last={i === priceOpts.web.length - 1}/>))}
      </DetailItem>
      {hasAppleIAP && (isAdmin || priceOpts.ios.length > 0) && <DetailItem>
        <DetailTitle title='Apple In-App Purchase'>
          <RenderPlanChip ios pricesIOS={priceOpts.ios} />
          <div className='flex-grow-1'/>
          {isAdmin && <ClrdButton color='primary' className='f-xmedium px-2'
            onClick={onEditClick} data-mode={STEPS.PRICING} data-iap={true}
            variant={priceOpts.ios.length < 1 ? 'contained' : 'text'}>
            {priceOpts.ios.length < 1 ? '+ Add Price' : '+ Add More'}
          </ClrdButton>}
          {priceOpts.ios.length > 0 && (<Tooltip enterDelay={0}
            title='Reorder - Pricing Options will show in the same order you decide.'>
            <IconButton disabled={priceOpts.ios.length < 2} onClick={reorderIOS}><ReorderIcon/></IconButton>
          </Tooltip>)}
        </DetailTitle>
        {priceOpts.ios.map((x, i) => (<Price STEPS={STEPS} type={type} add_on_type={add_on_type} key={x.id} disabled={!isAdmin} price={x} onEditClick={onEditClick}
          toggleActive={setConfirm} last={i === priceOpts.ios.length - 1}/>))}
        {priceOpts.ios.length > 0 && <div className='border-top pt-3'>
          <Typography style={{ color: "#37404D" }} variant='caption' className='font-weight-500'>
            Prices are managed directly from Apple App Store Connect. In case of subscriptions, the billing cycle too is managed from App Store Connect.&nbsp;
            You can view and edit these details under <b>Features&nbsp;>&nbsp;In-App Purchases</b> on{' '}
            <a target='_blank' rel='noopener noreferrer' href='https://appstoreconnect.apple.com/apps'>App&nbsp;Store&nbsp;Connect</a>.
          </Typography>
        </div>}
      </DetailItem>}
      {hasPlayIAP && (isAdmin || priceOpts.play.length > 0) && <DetailItem>
        <DetailTitle title='Google Play Subscriptions' >
          <RenderPlanChip android pricesPlay={priceOpts.play} />
          <div className='flex-grow-1'/>
          {isAdmin && <ClrdButton color='primary' className='f-xmedium px-2'
            onClick={onEditClick} data-mode={STEPS.PRICING} data-play={true}
            variant={priceOpts.play.length < 1 ? 'contained' : 'text'}>
            {priceOpts.play.length < 1 ? '+ Add Price' : '+ Add More'}
          </ClrdButton>}
          {priceOpts.play.length > 0 && (<Tooltip enterDelay={0}
            title='Reorder - Pricing Options will show in the same order you decide.'>
            <IconButton disabled={priceOpts.play.length < 2} onClick={reorderPlay}><ReorderIcon/></IconButton>
          </Tooltip>)}
        </DetailTitle>
        {priceOpts.play.map((x, i) => (<Price STEPS={STEPS} type={type} add_on_type={add_on_type} key={x.id} disabled={!isAdmin} price={x} onEditClick={onEditClick}
          toggleActive={setConfirm} last={i === priceOpts.play.length - 1}/>))}
        {priceOpts.play.length > 0 && <div className='border-top pt-3'>
          <Typography style={{ color: "#37404D" }} variant='caption' className='font-weight-500'>
            Prices and billing cycles are managed directly from Google Play Console.&nbsp;
            You can view and edit these details under <b>Monetize&nbsp;>&nbsp;Products&nbsp;>&nbsp;Subscriptions</b> on{' '}
            <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/console/developers'>Google Play Console</a>.
          </Typography>
        </div>}
      </DetailItem>}
      {hasVidCall && <DetailItem hasItem>
        <DetailTitle title={getVideoCallTitle()} className="mb-2" ><div className='flex-grow-1'/><EditBtn className="p-10" onClick={onEditClick} mode={STEPS.VIDEO_CALL} /></DetailTitle>
        <VideoCalling  data={data.vid_call} type={type} add_on_type={add_on_type} access={access} memoizeGcs={memoizeGcs} id={id} />
      </DetailItem>}
      <DetailItem>
        <DetailTitle title='Plan Description' className={!!data.description && "mb-2"} ><div className='flex-grow-1'/><EditBtn className="p-10" onClick={onEditClick} mode={STEPS.BASIC} /></DetailTitle>
        <Typography variant='h6' className={clsx('font-weight-500 text-wrap')} style={{whiteSpace:'pre-wrap', lineHeight: '24px', color: '#37404D'}}>
          {data.description || ''}
        </Typography>
      </DetailItem>
      <DetailItem>
        <DetailTitle title='Plan Includes' className="mb-2" ><div className='flex-grow-1'/><EditBtn className="p-10" onClick={onEditClick} mode={STEPS.INCLUDES} /></DetailTitle>
        <Points src='plan_includes' data={data} />
      </DetailItem>
      <DetailItem>
        <DetailTitle title='How it works' className="mb-2" ><div className='flex-grow-1'/><EditBtn className="p-10" onClick={onEditClick} mode={STEPS.HOW_IT_WORKS} /></DetailTitle>
        <Points src='how_it_work' data={data} />
      </DetailItem>
      {typeof edit.mode !== 'undefined' && <Editor onClose={onEditClick} onSave={onSave} id={id} data={data} {...edit} access={access} memoizeGcs={memoizeGcs} updateAccess={updateAccess}/>}
      {!!reorderData && (<ReorderDialog open
        listData={reorderData}
        onClose={closeReorder}
        onSubmit={onReorder}
        title='Reorder Prices'/>)}
      <Confirmation open={Boolean(confirm && confirm.price)} title='Please Confirm'
        handleCancel={setConfirm} handleChange={toggleActive}
        msg={confirm.active === 'Y' ? TXT_DEACTIVATE : TXT_ACTIVATE}
        confirmOption={confirm.active === 'Y' ? 'Yes, Deactivate' : 'Yes, Activate'}/>
    </Grid>
  );
};

export const ddt2str = (duration, duration_type) => {
  if (duration === 12 && duration_type === 'months') return '1 Year'; 
  else if (duration > 12 && duration % 12 === 0 && duration_type === 'months') return `${duration / 12} Years`; 
  else if (duration === 1) return `1 ${_.capitalize(duration_type).replace(/s$/, '')}`;
  else return `${duration} ${_.capitalize(duration_type)}`;
};

const Price = ({disabled, last, price, onEditClick, toggleActive, type, add_on_type, STEPS}) => {
  const { id, iap_id, play_product_id, method_ios, discount_enabled, discounted_price, currency, num_sessions, validity_enabled } = price;
  const hasActive = getActiveStatus(price || {}, "active");
  const hasActiveIos  = getActiveStatus(price || {}, "active_ios");
  const hasActiveAndroid = getActiveStatus(price || {}, "active_android");;
  const isMembershipAddOn = type === "add_on" && add_on_type === "membership";
  const isAddOn = type === "add_on" && !isMembershipAddOn;
  const isGroupClass = add_on_type === "group_class";
  const isOneToOne = add_on_type === "one_to_one";
  const isIAP = !!iap_id && method_ios === 'iap';
  const isPlay = !!play_product_id;
  const isActive = isIAP ? hasActiveIos : (isPlay ? hasActiveAndroid : hasActive);
  const effectivePrice = discount_enabled ? (discounted_price || 0) : price.price;
  let l1 = '';
  if(isAddOn){
    if(isGroupClass) l1 = "Group Classes Pack";
    else if(isOneToOne) l1 = "One To One Pack";
  }else{
    switch (price.mode) {
      case 'subscription':
        l1 = isIAP || isPlay ? 'Subscription' : `${_.capitalize(price.subs_duration_type)}ly Subscription`;
        break;
      default:
        l1 = `One Time Payment • ${ddt2str(price.duration, price.duration_type)}`;
        break;
    }
  }
  return (
    <div className='d-flex flex-row align-items-stretch'>
      <div className='py-4 pr-3'>
        <PriceAvatar isIAP={isIAP} isPlay={isPlay} currency={currency} discount={effectivePrice} />
      </div>
      <div className={`py-4 flex-grow-1 d-flex flex-column justify-content-center ${last ? '' : 'border-bottom'}`}>
        <Typography variant='body1' className='font-weight-600'
          style={{ color: '#42484b', whiteSpace: 'normal', marginBottom: '9px' }}>
          {l1}
        </Typography>
        <Typography variant='caption' color='textSecondary' className={`font-weight-500 ${isIAP ? 'text-uppercase' : ''} ${isPlay ? 'text-lowercase' : ''}`}>
          {isIAP || isPlay ? (iap_id || play_product_id) : <>
            Actual Price:{' '}
            <span style={{ color: '#42484b' }} className='font-weight-600'>
              {price.currency} {new Intl.NumberFormat(`${price.currency}`).format((_.round(effectivePrice, 2)))}
               {discount_enabled && <span style={{color:"#D73717"}}> <s>{price.currency} {new Intl.NumberFormat(`${price.currency}`).format((_.round(price.price, 2)))}</s></span>}
              <span></span>
            </span>
            {price.enable_trial_pack && <>
              {` • For ${price.trial_duration} ${_.capitalize(price.trial_duration_type)}: `}
              <span style={{ color: '#42484b' }} className='font-weight-600'>
                {price.trial_cost ? `${price.currency} ${price.trial_cost}` : 'Free'}
              </span>
            </>}
            {isAddOn && <span>
              <span> <span style={{ color: '#42484b' }}> • </span> validity </span>
              <span style={{ color: '#42484b' }} className='font_13_600'>{!validity_enabled ? "Unlimited" : ddt2str(price.duration, price.duration_type) }</span>
            </span>}
          </>}
        </Typography>
        {isAddOn && <Typography color='textSecondary' className='mt-8'><span className='font_13_500'>Sessions :</span> <span className='font_13_700 text-0d0d0d'>{num_sessions}</span></Typography>}
      </div>
      {!disabled && <div className={`py-4 d-flex align-items-center justify-content-center ${last ? '' : 'border-bottom'}`}>
        <FormControlLabel checked={isActive}
          onChange={toggleActive} classes={{ root: 'my-0' }}
          control={<Switch color='primary' inputProps={{'data-price': id, 'data-active': isActive ? 'Y' : 'N'}} />}
          label={isActive ? 'Active' : 'Inactive'}/>
      </div>}
      {!disabled && <div className={`py-4 d-flex align-items-center justify-content-center ${last ? '' : 'border-bottom'}`}>
        <EditBtn onClick={onEditClick} mode={STEPS.PRICING} price={id} />
      </div>}
    </div>
  );
};

const Duration = (props) =>{
  const { duration,className ,...rest } = props;
  return (
    <div className={clsx(className, "d-flex align-items-center")} {...rest}>
      <img src={timeIcon} className='mr-10' />
      <Typography variant="body1" >
        {duration ? <span className="font_15_600"><span >{duration}</span> mins </span> : <span className=' font_15_500 text-0d0d0d'> Not specified</span>}
      </Typography>
    </div>
  );
}

const VideoCalling = (props) => {
  const { type, add_on_type, data, id, access } = props;
  const {comp} = useContext(FirebaseAuthContext);
  const company = comp ? comp.data() : {};
  const isAddOn = type === 'add_on';
  const isMembership = !type || type === "regular";
  const isMembershipAddOn = isAddOn && add_on_type === 'membership';
  const isGroupClassEnabled = Boolean(_.get(company, "features.group_class.enabled", false));
  const isGroupClass = add_on_type === 'group_class';
  const isOneToOneEnabled = useMemo(() => {
    if (!data || !data.includes_calls) return false;
    else return true;
  }, [data]);
  const gcAccess = useMemo(() => {
    const _access = _.get(access, `packs.${id}.classes`, []);
    const _except = _.get(access, `packs.${id}.except`, [])
    return {access: _access, except : _except}
  }, [access, id]);
  const servicesAccess = useMemo(() => {
    const _access = _.get(access, `packs.${id}.services`, []);
    const _except = _.get(access, `packs.${id}.service_except`, [])
    return {access: _access, except : _except}
  }, [access, id]);
  const isGcAccessEnabled = useMemo(() => {
    const {access, except} = gcAccess || {};
    return !!(access && access.length);
  }, [gcAccess]);
  const isServiceAccessEnabled = useMemo(() => {
    const {access, except} = servicesAccess || {};
    return !!(access && access.length);
  }, [servicesAccess]);


  /* -----------  Render in case of Add On packs ------------*/
  if (isAddOn && !isMembershipAddOn) {
    if (isGroupClass && isGroupClassEnabled)
      return (
        <div className='pb-10'>
          {!isGcAccessEnabled && (
            <div className='pb-15'>
              <Typography
                variant="h6"
                className="font-weight-500"
                color="textSecondary"
                style={{ whiteSpace: 'pre-wrap' }}>
                Group Classes access is disabled for this plan
              </Typography>
            </div>
          )}
          {isGcAccessEnabled && <AccessDisplay name="Classes" idAccess={gcAccess} {...props} />}
        </div>
      );
    else return (
      <div>
        {/* <Duration className="pb-25" duration={_.get(data, 'duration')} /> */}
        {isServiceAccessEnabled && (
          <div className="mb-10">
            <Typography variant="h5" className="text-dark align-items-center mb-16">
              Services&nbsp;
              <span>
                <Tooltip title={'Lorem Ipsum ....'}>
                  <InfoOutlinedIcon style={{ color: '#65768C', fontSize: 16 }} />
                </Tooltip>
              </span>
            </Typography>
            <AccessDisplay name="Services" idAccess={servicesAccess} {...props} />
          </div>
        )}
      </div>
    );
  }
  /* -----------  Render in case of Regular Packs ------------*/
  return (
    <div className="pb-10">
      {!isOneToOneEnabled && (
        <div className={clsx("mb-25")}>
          <Typography variant="h6" className="font-weight-500" color="textSecondary" style={{ whiteSpace: 'pre-wrap' }}>
          Appointments are disabled for this plan
          </Typography>
        </div>
      )}
      {!!isOneToOneEnabled && (
        <div className={clsx((!isGcAccessEnabled || !isGroupClassEnabled) && "pb-15")}>
          <div className={clsx("d-flex flex-wrap", (isGcAccessEnabled && isGroupClassEnabled && !isServiceAccessEnabled && "mb-25"))}>
            {/* <Duration duration={_.get(data, 'duration')} style={{ marginRight: '90px' }} /> */}
            <div className="d-flex align-items-center">
              <img src={videoIcon} className="mr-10" />
              <Typography variant="body1" className="font-weight-500">
                <span className="font_15_600">{data.num_sessions}</span>
                <span> Calls {TXT[data.frequency] || ''}</span>
                {/* {`${data.num_sessions} ${TXT[data.frequency] || ''}`} */}
              </Typography>
            </div>
          </div>
          {isServiceAccessEnabled &&  <div className='mt-30 mb-10'>
            <Typography variant="h5" className="text-dark align-items-center mb-16">
            Services&nbsp;
            <span>
              <Tooltip title={"Lorem Ipsum ...."}>
                <InfoOutlinedIcon style={{ color: '#65768C', fontSize: 16 }} />
              </Tooltip>
            </span>
          </Typography>
            <AccessDisplay name="Services" idAccess={servicesAccess} {...props} />
          </div> }
        </div>
      )}
      {!!isGcAccessEnabled && isGroupClassEnabled && (
        <div>
          <Divider className="mb-25" />
          <Typography variant="h5" className="text-dark align-items-center mb-16">
            {' '}
            Group Classes&nbsp;
            <span>
              <Tooltip title={isMembership || isMembershipAddOn ? "The clients on this plan can book any of the specified group classes as long as their membership is active." : "The clients on this plan can book any of the group classes as specified below. The quota, however, is defined by the number of calls in the pricing option."}>
                <InfoOutlinedIcon style={{ color: '#65768C', fontSize: 16 }} />
              </Tooltip>
            </span>
          </Typography>
          <AccessDisplay name="Classes" idAccess={gcAccess} {...props} />
        </div>
      )}
    </div>
  );
}


const AccessDisplay = (props) =>{
  const { access, memoizeGcs, id, idAccess, name } = props;
  const access_type = useMemo(()=>{
    const { access, except } = idAccess;
    if (_.get(access, '0') === 'all') {
      if (!!except && !!except.length) return 'except_all';
      else return 'all';
    } else if (!!access || !!access.length) {
      return 'selected';
    }
    return '';

  },[idAccess])
  const gcChipData = useMemo(()=>{
    const out = [];
    if (!access_type) return '';
    const { access, except } = idAccess;
    if (access_type === 'all') {
      out.push({
        label:"All" + " " + name,
      });
    } else if (access_type === 'except_all') {
      except.forEach((gcId) => {
        out.push({
          label: _.get(memoizeGcs, `current.${gcId}.data.title`),
          id: gcId,
        });
      });
    } else if (access_type === 'selected') {
      access.forEach((gcId) => {
        out.push({
          label: _.get(memoizeGcs, `current.${gcId}.data.title`),
          id: gcId,
        });
      });
    }
    return out;
  },[memoizeGcs,idAccess, access_type, name ])
  return (
    <div className={"d-flex align-items-baseline"}>
      {access_type === "except_all" && <Typography className='mr-10 flex-shrink-0'>Access All Except : </Typography> }
      <ChipGroups chipClassName="mb-10" noBorder componentState={[]} fields={[...gcChipData]} />
    </div>
  );
}

const Points = ({ data, src }) => {
  if (!src) return null;
  const points = _.chain(data).get(src).without('').value();
  if (!points || !points.length) {
    let l1 = '';
    switch (src) {
      case 'plan_includes':
        l1 = 'Explain what this plan includes';
        break;
      case 'how_it_work':
        l1 = 'Explain how purchasing a plan works';
        break;
      default:
        return null;
    }
    return (
      <Grid item container justify='center' alignItems='center' >
        <Grid item xs={12}>
          <Typography variant='h2' className='fmb-10' align='center' >
            {l1}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' align='center' color='textSecondary'>
            Click on the edit button to add this info
          </Typography>
        </Grid>
      </Grid>
      );
  }
  return (
    <ul className='p-0 m-0 fmx-20'>
      {points.map((item, index) => (
        <li className={`p-0 m-0 ${index !== points.length - 1 ? 'fmb-10' : ''}`} key={index}>
          <Typography style={{lineHeight: '21px', color: '#37404D'}} variant='h6' className='font-weight-500'>
            {item}
          </Typography>
        </li>
      ))} 
    </ul>
  );
};

const DetailTitle = ({title, children, className}) => {
  return (
    <div className={clsx("d-flex flex-row align-items-center", className)}>
      <Typography variant='h5' className='align-items-center'>
        {title || ''}
      </Typography>
      {children}
    </div>
  );
}

const DetailItem = ({children, hasItem}) => { //hasItem is true ie  no need to add bottom space item will handle
  return (
    <Grid item className='fmb-20 w-100' >
      <Paper elevation={0}>
        <div 
        // className='d-flex flex-column fpt-15 fpr-15 fpl-25 fpb-25'
        className={clsx("d-flex flex-column fpt-15 fpr-15 fpl-25 ", hasItem ? "" :"pb-25")}
       
        >
          {children}
        </div>
      </Paper>
    </Grid>
  );
}

const EditBtn = ({onClick, ...data}) => {
  const props = _.mapKeys(data, (v, k) => (`data-${k}`));
  return (
    <IconButton onClick={onClick} {...props}><EditIcon /></IconButton>
  );
};

const useStyles = makeStyles(()=>({
  purchaseInfo:{
    padding:20,
    paddingTop:10,
    backgroundColor:"#FFF7F7",
    borderRadius:6,
    border:"1px solid #DB5353"
  }
}))

const PrivatePurchaseAlert = (props) =>{
  const { hidden, handleChange, planWebUrl } = props;
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const handleCopy = () =>{
    navigator.clipboard.writeText(planWebUrl);
    enqueueSnackbar("Link Copied Successfully !",{variant:"success"});
  }

  return (
    <div className={`fmb-20 w-100 ${classes.purchaseInfo}`}>
      <div className="d-flex justify-content-between">
        <FormControlLabel
          control={<Checkbox checked={hidden} onChange={handleChange} color="primary" />}
          classes={{
            root: 'fmb-0',
            label: 'font_15_600',
          }}
          label="Make it a Hidden Plan - Enable purchase via shareable link"
        />
        {!!hidden && (
          <Button onClick={handleCopy} color='primary' variant='text'>
            Copy Link&nbsp;<LinkIcon />
          </Button>
        )}
      </div>
      <Typography className="font_13_400 fml-30">
        This plan is inactive and will not be listed on your purchase page.
        You can still share a direct link to this plan with clients.{' '}
        {!hidden && 'Enable the checkbox above in order to do so.'}
      </Typography>
    </div>
  );

}

export default DetailTab;

import { Typography, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import React, { useState, useMemo, useEffect } from 'react';
import { FormTextField } from 'fitbud/components/form-fields';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { fakeEvent } from 'fitbud/utils/helpers';
import _ from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import { DatePickerField } from 'fitbud/views/groupClasses/createEditForm/scheduling';
import { isPreviousDay } from 'fitbud/views/groupClasses/helper';
import moment from 'moment';
import appRdxFun from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { oprtnlDateFormat } from 'fitbud/utils/constants';
import { useSnackbar } from 'notistack';
import { bffInvitePurchase } from 'fitbud/api';

export const getPricingOption = (id, pack_id, options) => {
  const pack = _.find(options, (p) => p.pack_id === pack_id);
  let option = _.find(pack.price_opts || [], (o) => o.id === id);
  if (!option) {
    //ie case of subscription packs with custom id.
    option = {
      id: 'custom',
      duration: 6,
      duration_type: 'weeks',
      mode: 'one_time',
      ref_name: pack.ref_name,
      title: pack.title,
      pack_id,
    };
  }

  return { pack, option };
};

const GrantAccessDialog = (props) => {
  const { id, onClose, packOptions, userProfileId, data, onSuccess } = props;
  const isNew = id === 'new';
  const dispatch = useDispatch();
  const [pricingOption, setPricingOption] = useState(() => {
    const pack = data?.pack;
    if (_.isEmpty(pack) || isNew) return pack;
    const { id, pack_id } = pack;
    return { pack_id, id };
  });
  const [validity, setValidity] = useState(data?.dateExpiry ? new Date(data.dateExpiry) : new Date());
  const { showLoader, hideLoader } = appRdxFun(dispatch);
  const { enqueueSnackbar } = useSnackbar();

  const onPlanChange = (e) => {
    const value = e.target.value;
    const [id, pack_id] = value.split(',');
    setPricingOption({ id, pack_id });
    const { pack, option } = getPricingOption(id, pack_id, packOptions);
    const { duration, duration_type } = option;
    let validity_date = new Date();
    if (option.mode === 'one_time') {
      validity_date = moment().add(duration, duration_type);
    }
    setValidity(validity_date);
  };
  const handleValidity = (time) => {
    const _start = moment(time);
    setValidity(_start);
  };

  const onSubmit = async () => {
    const start_date = data?.start_date || moment().format(oprtnlDateFormat);
    const end_date = moment(validity).format(oprtnlDateFormat);
    const pack_id = pricingOption?.pack_id;
    const price_id = pricingOption?.id;
    const pid = userProfileId;
    let payload = { pid, pack_id, price_id, start_date, end_date };
    if (!isNew) payload.purchase_key = id;
    // return;
    showLoader();
    try {
      const response = await bffInvitePurchase(payload);
      const { success, message } = response?.data;
      if (success) {
        if (onSuccess) await onSuccess();
        enqueueSnackbar(message, { variant: 'success' });
        hideLoader();
        onClose && onClose();
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log('>>>> error in submit', err);
    }
  };

  return (
    <Dialog
      open
      toolbarClass="height-60"
      paperClass={'width-600'}
      buttonColor="primary"
      buttonSize="f-medium"
      appBarColor="bg-grey-new"
      titleFont="h3"
      onClose={onClose}
      onSave={onSubmit}
      actionText="Save"
      title={isNew ? 'Grant Access' : 'Edit Access'}>
      <div className="p-20">
        <div className="info-box p-15 mb-15">
          <Typography className="font_13_500 lineht_1_5">
            The clients on this plan can book any of the group classes/services as specified below. The quota, however,
            is defined by the number of calls/appointments in the pricing option.
          </Typography>
        </div>
        <PlanField
          disabled={!isNew}
          allowCustom={false}
          priceDoc={pricingOption}
          noCustom
          packOptions={packOptions}
          onChange={onPlanChange}
        />
        <FormTextField label="Validity" fullWidth>
          <DatePicker
            allowKeyboardControl
            animateYearScrolling={true}
            disablePast={true}
            onChange={handleValidity}
            value={validity}
            name="validity"
            format="MMM D, YYYY"
            inputVariant="outlined"
            TextFieldComponent={DatePickerField}
            keyboardButtonProps={{
              'aria-label': 'date',
            }}
            // disabled={!isNew}
            disabled={!!isPreviousDay(validity)}
          />
        </FormTextField>
      </div>
    </Dialog>
  );
};

export const PlanField = ({ priceDoc, onChange, packOptions, disabled }) => {
  const list = useMemo(() => {
    const out = [];
    if (!packOptions || !packOptions.length) return out;
    packOptions.forEach((plan) => {
      if (!plan || !plan.price_opts || !plan.price_opts.length) return;
      if (!plan.active && !plan.activeV2 && !plan.hidden) return;
      let hasSub = false,
        count = out.length;
      plan.price_opts.forEach((pricing) => {
        if (!pricing || !(pricing.active || pricing.activeV2) || !pricing.mode) return;
        if (pricing.mode === 'subscription') {
          hasSub = true;
          return;
        }
        out.push({
          label: plan.ref_name + ' - ' + pricing.duration + ' ' + pricing.duration_type,
          pack_id: plan.pack_id,
          id: pricing.id,
        });
      });
      if (count === out.length && hasSub) out.push({ label: plan.ref_name, pack_id: plan.pack_id, id: 'custom' });
    });
    return out;
  }, [packOptions]);
  useEffect(() => {
    if (_.size(list) && _.isEmpty(priceDoc)) {
      //if list ready and not one selected than set by default one.
      const opt = list[0];
      const { pack_id, id } = opt;
      const value = id + ',' + pack_id;
      _.delay(() => {
        onChange(fakeEvent('pack', value));
      }, 200);
    }
  }, [list, priceDoc]);
  return (
    <FormTextField fullWidth label={'Select Pack'}>
      <Select
        IconComponent={DropDownIcon}
        value={`${priceDoc?.id},${priceDoc?.pack_id}`}
        disabled={!!disabled}
        input={
          <OutlinedInput
            classes={{
              root: 'medium',
              input: 'size_16_500 select-medium',
            }}
            name="pack"
          />
        }
        onChange={onChange}
        required>
        {list.map(({ label, pack_id, id }, index) => {
          return (
            <MenuItem key={`${index}-${pack_id}-${id}`} value={id + ',' + (pack_id || '')}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormTextField>
  );
};

export default GrantAccessDialog;

import React, { Suspense, useContext, useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { AnalyticsContext } from "fitbud/providers/analytics";
import { RoleContext } from "fitbud/providers/roleProvider";
import LoaderError, { ErrorBoundary } from "fitbud/components/loaders-errors";
import PageNotFound from "fitbud/views/pageNotFound";
import FeedbackModal from "fitbud/components/Feedback"
import { get,debounce } from "lodash";
import ProtectedRoutes from "./protected-routes";
import ChangePasswordDialog from 'fitbud/components/changePasswordDialog';
import BlockingLoader from "fitbud/components/BlockingLoader";
import ImageViewer from "fitbud/components/ImageViewer";
import WorkInProgress from "fitbud/views/wip";
import { bffUpdateHubspot } from 'fitbud/api/index';
import { HUBSPOT_PROPS } from "fitbud/utils/constants";
import GroupChat from "fitbud/views/groupChat";
import moment from "moment";
import AppNotification from "fitbud/views/appNotification";
import {ACL_FEATURES_KEY} from "fitbud/providers/acl-provider";


const Home = React.lazy(() => import("fitbud/views/dashboard"));
const Exercises = React.lazy(() => import("fitbud/views/exercises"));
const Workouts = React.lazy(() => import("fitbud/views/workouts"));
const SchedulePlanner = React.lazy(() =>
  import("fitbud/views/schedulePlanner")
);
const NutritionSchedulePlanner = React.lazy(() =>
  import("fitbud/views/nutritionSchedulePlanner")
);
//schedules- old view || schedulePlanner-latest view || keep both.
const AppTheming = React.lazy(() => import("fitbud/views/appTheming"));
const Users = React.lazy(() => import("fitbud/views/users"));
const Companies = React.lazy(() => import("fitbud/views/backoffice"));
const FoodRecipes = React.lazy(() => import("fitbud/views/foodRecipes"));
const Recipes = React.lazy(() => import("fitbud/views/recipes"));
const Meals = React.lazy(() => import("fitbud/views/meals"));
const Cardio = React.lazy(() => import("fitbud/views/cardio"));
const Supplements = React.lazy(() => import("fitbud/views/supplements"));
const Supplement = React.lazy(() => import("fitbud/views/supplement"));
const Trainer = React.lazy(() => import("fitbud/views/trainer"));
const FAQ = React.lazy(() => import("fitbud/views/faq"));
const PaymentPlan = React.lazy(() => import("fitbud/views/plan"));
const LiveSessions = React.lazy(() => import("fitbud/views/liveSessions"));
const GroupClasses = React.lazy(() => import("fitbud/views/groupClasses"));
const Services = React.lazy(()=>import("fitbud/views/services"))
const SessionSetting = React.lazy(()=>import("fitbud/views/sessionSetting"));
const CMS = React.lazy(() => import("fitbud/views/cms"));
const CIDInfo = React.lazy(() => import("fitbud/views/uthbts/cid-info"));
const Settings = React.lazy(() => import("fitbud/views/settings"));
const Challenges = React.lazy(() => import("fitbud/views/challenges"));
const Broadcasts = React.lazy(() => import("fitbud/views/broadcasts"));
const ExploreV2 = React.lazy(()=>import("fitbud/views/exploreV2"));
const ExploreConfiguration = React.lazy(()=>import("fitbud/views/exploreV2/config"))
const Curation = React.lazy(()=>import("fitbud/views/curated/detail"))
const ReferralView = React.lazy(() => import("fitbud/views/referralView"));
const Upgrade  = React.lazy(()=>import("fitbud/views/upgrade"));
const UpgradeElite = React.lazy(()=>import("fitbud/views/upgradeElite"));
const TrialExpired = React.lazy(()=>import("fitbud/views/trialExpired"))

const updateHbsProps = debounce((tEnabled, isOwner, cid) => {
  bffUpdateHubspot({
    [HUBSPOT_PROPS.ACCOUNT_ACCESS_TYPE]: tEnabled ? "Enterprise" : "Independent",
    [HUBSPOT_PROPS.CONTACT_TYPE]: isOwner ? "Owner" : "Trainer",
    [HUBSPOT_PROPS.CID]: cid,
    [HUBSPOT_PROPS.LAST_ACTIVE_TIMESTAMP]: moment().format("YYYY-MM-DD")
  });
}, 5 * 60 * 1000, { leading: true, trailing: false });


const AppRoutes = props => {
  const { comp, isFBStaff, cid = "" } = useContext(FirebaseAuthContext);
  const { event } = useContext(AnalyticsContext);
  const { isTrainerRole, tEnabled, isOwner } = useContext(RoleContext);
  const company = comp ? comp.data() : {};
  const isExplore = get(company, '_next') === true;

  // Update last active prop
  useEffect(() => {
    updateHbsProps(tEnabled, isOwner, cid);
    event('last_seen');
  }, [cid, isOwner, props.location, tEnabled, event]);

  return (
    <>
      <ErrorBoundary className="min-vh-100">
        <Suspense fallback={<LoaderError className="min-vh-100" />}>
          <Switch>
            <Redirect exact path="/" to="/home" />
            <Redirect exact path="/get-started" to="/home" />
            <Route exact path="/home" component={Home} />
            <Redirect exact from="/clients/chats" to="/messages/chats" />
            <Redirect exact from="/clients/chats/:id?" to="/messages/chats/:id" />
            <Redirect exact from="/fitness" to="/fitness/exercises" />
            <Redirect exact from="/nutrition" to="/nutrition/foods" />
            <Redirect exact from="/clients" to="/clients/active" />
            <Redirect exact from="/messages" to="/messages/chats" />
            <Redirect exact from="/config" to={isTrainerRole ? "/config/profile" : "/config/welcome-mail"} />
            <Redirect exact from="/explore" to={isExplore ? "/explore/workout" : "/explore/resources"} />
            <Redirect exact from="/live" to={"/live/calendar"} />
            <Redirect exact from="/curated" to="/curated/foru" />
            {/* {!isTrainerRole ?
              <Redirect exact from="/config" to="/config/app-pages" />
            : <Redirect exact from="/config" to="/config/profile" />} */}
            {/* <Route path="/config/app-pages" component={AppPages} />
            <Route path="/config/profile" component={TrainerProfile} />
            <Route path="/config/general" component={General} /> */}
            {isFBStaff && (
              <Route path="/companies" component={Companies} />
            )}
            <Route path="/config" component={Settings}/>
            <Route path="/referral-program" component={ReferralView}/>
            <ProtectedRoutes path="/fitness/exercises/:id?" component={Exercises} />
            <ProtectedRoutes path="/fitness/workouts/:id?" component={Workouts} />
            <ProtectedRoutes path="/fitness/schedules/:id?" component={SchedulePlanner} />
            <ProtectedRoutes path="/fitness/cardio/:id?" component={Cardio} />
            <ProtectedRoutes exact path="/clients/:page/:id?" component={Users} />
            <ProtectedRoutes exact path="/messages/chats/:id?" render={(props) => <Users {...props} page="chats" scope="messages"/>} />
            {/* <Route exact path="/messages/broadcasts/:id?" component={Broadcasts} /> */}
            <ProtectedRoutes exact path="/messages/broadcasts/:id?" fkey={ACL_FEATURES_KEY.BROADCAST} component={Broadcasts} />
            <ProtectedRoutes exact path="/messages/in-app-notifications/:id?" component={AppNotification} fkey={ACL_FEATURES_KEY.IN_APP_NOTIFICATION} />
            <ProtectedRoutes exact path="/curated/:id" component={Curation} />
            <ProtectedRoutes exact path="/explore/configuration" component={ExploreConfiguration} fkey={ACL_FEATURES_KEY.EXPLORE} />
            {/* <Route exact path="/explore/:type/:collection?/:id?" component={ExploreV2} /> */}
            <ProtectedRoutes exact path="/explore/:type/:collection?/:id?" fkey={ACL_FEATURES_KEY.EXPLORE} component={ExploreV2} />
            {/* {isGroupChat && <Route exact path="/messages/group-chats/:id?" component={GroupChat} />} */}
            {<ProtectedRoutes exact path="/messages/group-chats/:id?" fkey={ACL_FEATURES_KEY.GROUP_CHAT} component={GroupChat} />}
            <ProtectedRoutes path="/challenges/:id?" fkey={ACL_FEATURES_KEY.CHALLENGES} component={Challenges}/>
            <Route path="/client-app/theme" component={AppTheming} />

            {/* {!isTrainerRole && !!tEnabled && (
              <ProtectedRoutes exact path="/trainers/:id?" component={Trainer} />
            )} */}
            {!isTrainerRole && (
              <ProtectedRoutes exact path="/trainers/:id?"  fkey={ACL_FEATURES_KEY.TRAINERS} component={Trainer} />
            )}
            <ProtectedRoutes
              path="/nutrition/foods/:id?"
              component={FoodRecipes}
            />
            <ProtectedRoutes
              path="/nutrition/recipes/:id?"
              component={Recipes}
            />
            <ProtectedRoutes path="/nutrition/daily-meals/:id?" component={Meals} />
            <ProtectedRoutes
              path="/nutrition/daily-supplements/:id?"
              component={Supplements}
            />
            <ProtectedRoutes
              path="/nutrition/supplements/:id?"
              component={Supplement}
            />
            <ProtectedRoutes path="/nutrition/schedules/:id?" component={NutritionSchedulePlanner} />
            <ProtectedRoutes path="/faq" component={FAQ} />
            <ProtectedRoutes path="/video-calls" component={LiveSessions} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} />
            <ProtectedRoutes path="/live/calendar" component={LiveSessions} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} />
            <ProtectedRoutes path="/live/group-classes/:id?" component={GroupClasses} fkey={ACL_FEATURES_KEY.GROUP_CLASS} />
            <ProtectedRoutes path="/live/services/:id?" component={Services} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} />
            {isOwner &&  <ProtectedRoutes path="/live/configuration" fkey={ACL_FEATURES_KEY.VIDEO_CALLING} component={SessionSetting} />}    
            {!isTrainerRole && <ProtectedRoutes path="/plans/:id?" component={PaymentPlan} fkey={ACL_FEATURES_KEY.PAYMENTS_PLAN} />}
            {/* <Route path="/explore/nutrition/:id?" component={Workouts} /> */}
            {/* <Route path="/explore/insights/:id?" component={Workouts} /> */}
            <ProtectedRoutes path="/reports" component={WorkInProgress} />
            <Route path="/upgrade" component={Upgrade} />
            <Route path="/upgrade-elite" component={UpgradeElite} />
            <Route path="/trial-expired" component={TrialExpired} />
            <ProtectedRoutes path="/website" component={CMS} fkey={ACL_FEATURES_KEY.WEBSITE} />
            <Route path="/whats-cid" component={CIDInfo} />
            <Route component={PageNotFound} />
          </Switch>
          <ChangePasswordDialog />
          <BlockingLoader />
          <ImageViewer />
          <FeedbackModal />
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default AppRoutes;

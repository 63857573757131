import { Checkbox, CircularProgress, List, makeStyles, Typography, FormControlLabel } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreateIcon from '@material-ui/icons/Create';
import { RoleContext } from 'fitbud/providers/roleProvider';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { countUserBookings } from 'fitbud/utils/scheduling';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { isActiveUser } from "fitbud/views/users/helpers";

const useStyles = makeStyles((theme) => ({
  warningBox:{
    backgroundColor:'#FFFBF3',
    border: '1px solid #FFDEA9',
    borderRadius: '3px',
    padding:'15px'
  },
}));

const showWarningText = (bookingsCount, videoPlan) => {
  if (!videoPlan || !videoPlan.includes_calls) return "This client is not on a plan that includes 1 on 1 appointments";
  if (bookingsCount <= 0) return `This client has used all appointments in this ${videoPlan.frequency || ''}`;
  return `${bookingsCount || ' - '} ${bookingsCount === 1 ? 'appointment' : 'appointments'} remaining in this ${videoPlan.frequency || ''}`;
}

const UserNameBox = ({user, onClick, showTrainerName, disableEdit }) => {
  let  {name, email, trainer_name} = user;
  if(!name || !email){
    // Get name and email from profile if user structure is different than elastic one
    let {name: _name, email: _email} = user.profile
    name = _name;
    email = _email;
  }
  return (
    <div 
      className={`w-100 d-flex justify-content-between 
        align-items-start fp-20 border rounded shadow-sm ${!disableEdit ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <div className='d-flex flex-column' style={{width: '90%'}}>
        <Typography variant='h3' noWrap >
          {name || ''}
        </Typography>
        <Typography variant='body1' color='textSecondary' noWrap className='fmt-5'>
          {email || ''}
        </Typography>
        {!!showTrainerName && !!trainer_name ? <div className='d-flex fmt-10'>
          <AccountBoxIcon fontSize='small' />
          <Typography variant='body1' className='fml-10'>{trainer_name}</Typography>
        </div> : null}
      </div>
      {!disableEdit && <div>
        <CreateIcon fontSize='small' />
      </div>}
    </div>
  )
}
const UserData = ({cid, date, user, rescheduleMode, 
  onNameBoxClick, checkTrainerBooking, isTrainerBooking, 
  isVideoEnabled, userBookingsCount, setUserBookCount, disableEdit }) =>  {
  let {vid_call, uid: userId, startDate } = user || {};
  const { enqueueSnackbar } = useSnackbar();
  const [_isLoading, set_isLoading] = useState(false)
  let isActive = isActiveUser(user);
  // useEffect(()=>{
  //   if(isVideoEnabled && startDate && isActive){
  //     // Update parent's state for user booking count as startDate is available from this component
  //     // User booking count is needed to decide whether to make an api call or not
  //     set_isLoading(true);
  //     const { frequency, num_sessions } = vid_call;
  //     countUserBookings(cid, { date: moment(date).toDate(), uid: userId, startDate, frequency}).then((res) => {
  //       setUserBookCount(num_sessions - res); //User booking count error handle in parent component onsubmit
  //     }).catch((err) => {
  //       console.log(err);
  //       enqueueSnackbar(DEFAULT_ERROR, {variant: 'error'});
  //     }).finally(()=> {
  //       set_isLoading(false)
  //     })
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[userId, date, isVideoEnabled])


  const { isOwner, tEnabled } = useContext(RoleContext);
  const classes = useStyles();
  return (
    <div>
      <div className={`${rescheduleMode ? 'fmb-20': ''}`}>
        <UserNameBox
          user={user} 
          showTrainerName={!rescheduleMode && !!tEnabled && isOwner}
          onClick={disableEdit ? null : onNameBoxClick } 
          disableEdit={disableEdit} />
      </div>
      {/* Deduct user bookings checkbox */}
      { isOwner && isVideoEnabled ? (
      <div className='d-flex align-items-center mt-15'>
        {/* {disabled checkbox when user call quota not available} */}
        <FormControlLabel classes={{root: 'mb-0'}}
          disabled={userBookingsCount <= 0} checked={!isTrainerBooking} onChange={checkTrainerBooking}
          control={<Checkbox color="primary"/>}
          label="Count this booking in client's quota"/>
        {_isLoading && <CircularProgress size={16} />}
      </div>) : null}

      {/* Warning box */}
      {/* {!rescheduleMode && <div className={`${classes.warningBox} fmb-20 ${!isVideoEnabled || !isOwner || !(userBookingsCount && userBookingsCount > 0) ? 'fmt-20' : 'fmt-10'}`}>
        <List disablePadding className={classes.warningList}>
          <Typography variant='caption' component='li' className='font-weight-500' color='textSecondary'>
              {_isLoading ? 'Please wait...' : showWarningText(userBookingsCount, !isActive ? false : vid_call)}
          </Typography>
        </List>
      </div>} */}
    </div>
  );
}
export default UserData;


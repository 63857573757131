import { Card, Drawer, Typography } from '@material-ui/core';
import { DialogAppBar } from 'fitbud/components/Dialog';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PurchaseCard from './purchase-card';
import { ClrdButton } from 'fitbud/components/form-fields';
import GrantAccessDialog from './grant-access-dialog';
import usePacks from 'fitbud/hooks/usePacks';
import Confirmation from 'fitbud/components/confirmationDialog';
import appRdxFn from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { DEFAULT_ERROR, oprtnlDateFormat } from 'fitbud/utils/constants';
import { bffInvitePurchase } from 'fitbud/api';
import serviceImg from "fitbud/images/services.svg";

const sortByDate = (arr, key, format = '') => {
  //array of object, key: date key, format : format of date.
  return _.sortBy(arr, (a, b) => {
    const d1 = _.get(a, key),
      d2 = _.get(b, key);
    const m1 = moment(d1, format),
      m2 = moment(d2, format);
    return m1.unix() - m2.unix();
  });
};

const activePurchase = (p, tz) => {
  return p.subId
    ? p.status === 'active' || p.status === 'cancelled'
    : moment(p.dateExpiry).tz(tz).endOf('day') > moment() &&
        moment(p.dateLast).tz(tz).startOf('day') < moment() &&
        p.productId !== 'sessions_invite' &&
        p.productId !== 'drop_in' &&
        (p.accessType === 'membership' || p.accessValue > 0);
};

const Placeholder = () => {
  return (
    <Card className="mb-15">
      <div className="h-100 d-flex flex-column justify-content-center align-items-center my-40">
        <img style={{height:100}} src={serviceImg} />
        <Typography className="font_13_600 text-dark-grey mt-15">No Active Plans Available</Typography>
      </div>
    </Card>
  );
};

const ManageAccess = (props) => {
  const { purchases, handleClose, userProfileId, onSuccess, userDoc } = props;
  const { cid } = useContext(FirebaseAuthContext);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [editId, setEditId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const { packs, loading: packLoading } = usePacks();
  const { showLoader, hideLoader } = appRdxFn(dispatch);
  const openEdit = (id) => setEditId(id);
  const closeEdit = () => setEditId('');
  const setDelete = (id) => setDeleteId(id);
  const clearDelete = () => setDeleteId('');
  const userTz = _.get(userDoc, 'profile.time_zone') || 'UTC';

  const packOptions = useMemo(() => {
    const out = [];
    _.forEach(packs, (p) => {
      const data = _.get(p, 'data');
      if (data.archive) return;
      out.push({ pack_id: p._id, ...data });
    });
    return out;
  }, [packs]);

  const [purchased, manually] = useMemo(() => {
    if (_.isEmpty(purchases)) return [[], []];
    const self = [],
      add = [];
    _.keys(purchases).forEach((key) => {
      const p = _.get(purchases, key);
      if (!activePurchase(p, userTz)) return;
      if (p.src === 'invite') add.push({ id: key, ...p }); // added
      else self.push({ id: key, ...p }); //purchased.
    });
    return [sortByDate(self, 'dateFirst'), sortByDate(add, 'dateFirst')];
  }, [purchases, userTz]);

  const [delConfTitle, delConfSubtitle] = useMemo(() => {
    const p = _.get(purchases, deleteId);
    if (!p) return ['', ''];
    const pack_title = _.get(p, 'pack.title', '');
    return [`Delete Pack`, `Are you sure you want to delete this ${pack_title} access.`];
  }, [purchases, deleteId]);

  const deletePurchase = async () => {
    const purchase = _.get(purchases, deleteId, {});
    const { dateFirst } = purchase;
    const { id, pack_id } = _.get(purchase, 'pack', {});
    const payload = {
      pid: userProfileId,
      pack_id: pack_id,
      purchase_key: deleteId,
      price_id: id,
      start_date: moment(dateFirst).format(oprtnlDateFormat),
      end_date: moment().format(oprtnlDateFormat), //TO delete pass today's date.
    };
    try {
      showLoader();
      const response = await bffInvitePurchase(payload);
      const { success, message } = response.data;
      if (!success) throw Error(message);
      if (onSuccess) await onSuccess();
      enqueueSnackbar('Access Removed Successfully.', { variant: 'success' });
      clearDelete();
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    } finally {
      hideLoader();
    }
  };

  const isPurchasedAvailable = !!_.size(purchased);
  const isManuallyAddedAvailable = !!_.size(manually);
  const isEmpty = !isPurchasedAvailable && !isManuallyAddedAvailable;

  return (
    <Drawer open anchor="right">
      <DialogAppBar title={'Manage Access'} headingCenter onClose={handleClose} />
      <div className="px-20 pt-25 pb-15">
        {isEmpty && <Placeholder />}
        {!isEmpty && (
          <>
            {/* Purchased packs */}
            {isPurchasedAvailable && (
              <div className="pb-15">
                <Typography className="font_15_600 mb-15">Purchased Packs</Typography>
                {purchased &&
                  purchased.map((purchase) => {
                    return <PurchaseCard userTz={userTz} data={purchase} key={purchase.key + '__purchased'} />;
                  })}
              </div>
            )}
            {/* Manually added packs */}
            {isManuallyAddedAvailable && (
              <div>
                <Typography className="font_15_600 mb-15">Manually Added</Typography>
                {manually &&
                  manually.map((purchase) => {
                    return (
                      <PurchaseCard
                        allowEdit
                        openEdit={() => openEdit(purchase.id)}
                        onDelete={() => setDelete(purchase.id)}
                        data={purchase}
                        userTz={userTz}
                        key={purchase.key + '__purchased'}
                      />
                    );
                  })}
              </div>
            )}
          </>
        )}
        <div className="w-100 text-center">
          <ClrdButton
            disabled={packLoading}
            className="font_15_600"
            color="primary"
            title="Grant Access"
            onClick={() => openEdit('new')}
          />
        </div>
      </div>
      {/* If edit id === new ie grant access other wise edit  */}
      {editId && (
        <GrantAccessDialog
          packOptions={packOptions}
          id={editId}
          data={editId === 'new' ? {} : _.get(purchases, editId)}
          onClose={closeEdit}
          userProfileId={userProfileId}
          onSuccess={onSuccess}
        />
      )}
      {!!deleteId && (
        <Confirmation
          open
          handleClose={clearDelete}
          handleCancel={clearDelete}
          showCloseIcon
          title={delConfTitle || ''}
          msg={delConfSubtitle}
          cancelOption="Cancel"
          confirmOption="Yes, Delete"
          handleChange={deletePurchase}
        />
      )}
    </Drawer>
  );
};

export default ManageAccess;

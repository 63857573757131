import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  InputAdornment,
  Grid,
  Typography,
  TextField,
  Switch,
  Divider,
} from '@material-ui/core';
import _ from 'lodash';
import { useInput, usePicker, useToggle } from 'fitbud/hooks/form';
import { ToggleLabel } from 'fitbud/components/toggles';
import { FormTextField } from 'fitbud/components/form-fields';
import GcAccess from './gcAccess';
import ServiceAccess from './servicesAccess';
import { useSnackbar } from 'notistack';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const FT = [
  { value: 'week', label: 'Per Week' },
  { value: 'month', label: 'Per Month' },
  { value: 'plan', label: 'Total' },
];

export const getAccessType = (gcAccess, defaultValue) => {
  const access = _.get(gcAccess, 'access', []);
  const except = _.get(gcAccess, 'except');
  if (access.includes('all')) {
    if (!except || !except.length) return 'all';
    if (!except || !!except.length) return 'except_all';
  } else if (access.length) return 'selected';
  else return defaultValue || 'none'; //default value..
};

const RegularCalling = (props) => {
  const { fnRef, compareDirty, data = {}, access: accessState, gcAccess, id, memoizeGcs, setDirty, serviceAccess } = props;
  const calling = data.vid_call || {};
  const {comp} = useContext(FirebaseAuthContext)
  const company = comp ? comp.data() : {};
  const { enqueueSnackbar } = useSnackbar();
  const [enabled, toggleEnabled] = useToggle(calling.includes_calls || false);
  const [duration, setDuration] = usePicker(calling.duration || 15);
  const [selectedGcs, setSelectedGcs] = useState(_.get(gcAccess, 'access', [])); //local memory..
  const [exceptGcs, setExceptGcs] = useState(_.get(gcAccess, 'except', []));
  const [gc_access_type, setGcAccessType] = useState(getAccessType(gcAccess));
  const [gc_enabled, setGcEnabled] = useState(getAccessType(gcAccess) === 'none' ? false : true);
  const [selectedServices, setSelectedServices] = useState(_.get(serviceAccess, 'access', [])); //local memory..
  const [exceptServices, setExServices] = useState(_.get(serviceAccess, 'except', []));
  const [service_access_type, setServiceAccessType] = useState(getAccessType(serviceAccess));
  const isExceptAll = gc_access_type === 'except_all';
  const isSpecific = gc_access_type === 'selected';
  const isExceptServices = service_access_type === "except_all";
  const isSpecificServices = service_access_type === "selected";
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const {
    parsed: num_sessions,
    props: nProps,
    isValid: nValid,
  } = useInput(calling.num_sessions || 1, 'number * 1:min');
  const [frequency, setFreq] = usePicker(calling.frequency || 'week');

  const classesWithAllAccess = useMemo(() => {
    return _.get(accessState, 'classes.all.packs', []);
  }, [accessState]);

  useEffect(() => {
    if (compareDirty) compareDirty({ vid_call: { includes_calls: enabled, duration, frequency, num_sessions } });
    if (!_.isEqual(selectedGcs, _.get(accessState, `packs.${id}.classes`, []))) setDirty(true);
    if (!_.isEqual(exceptGcs, _.get(accessState, `packs.${id}.except`, []))) setDirty(true);
    if (!_.isEqual(selectedServices, _.get(accessState, `packs.${id}.services`, []))) setDirty(true);
    if (!_.isEqual(exceptServices, _.get(accessState, `packs.${id}.service_except`, []))) setDirty(true);
    fnRef.current = (setState, setGcAccess, setServicesAccess) => {
      if (!setState) return false;
      if (enabled && !nValid()) return false;
      if (gc_access_type === 'selected' && !!gc_enabled && !selectedGcs.length) {
        enqueueSnackbar('Select at lease one group class in case of selected mode', { variant: 'error' });
        return false;
      }
      if (!!enabled && (['selected', 'none'].includes(service_access_type)) && !selectedServices.length) {
        enqueueSnackbar('Select at lease one services in case of selected mode', { variant: 'error' });
        return false;
      }
      setState((curr) => ({
        ...curr,
        vid_call: {
          includes_calls: enabled,
          duration,
          frequency,
          num_sessions,
        },
      }));
      setGcAccess({ access: selectedGcs, except: exceptGcs });
      setServicesAccess && setServicesAccess({ access: selectedServices, except: exceptServices });
      return true;
    };
  }, [
    fnRef,
    compareDirty,
    nValid,
    enabled,
    duration,
    frequency,
    num_sessions,
    gc_access_type,
    selectedGcs,
    exceptGcs,
    gc_enabled,
    selectedServices,
    service_access_type,
    exceptServices,
  ]);

  const handleSetValues = (values) => {
    if (isExceptAll) setExceptGcs(values);
    else if (isSpecific) setSelectedGcs(values);
  };

  const handleServiceValues = (values) =>{
    if (isExceptServices) setExServices(values);
    else if (isSpecificServices) setSelectedServices(values);
  }

  const setAccessOnAccessType = (type) =>{
    if (type === 'all') {
      setSelectedGcs(['all']);
      setExceptGcs([]);
    } else if (type === 'selected') {
      setSelectedGcs([...classesWithAllAccess]);
      setExceptGcs([]);
    } else if (type === 'except_all') {
      setSelectedGcs(['all']);
      setExceptGcs([]);
    } else if (type === 'none') {
      setSelectedGcs([]);
      setExceptGcs([]);
    }
  }

  const setServicesAccessOnAccessType = (type) =>{
    if(type === "all"){
      setSelectedServices(["all"]);
      setExServices([]);
    }else if(type === "selected"){
      setSelectedServices([]);
      setExServices([]);
    }else if(type === "except_all"){
      setSelectedServices(["all"]);
      setExServices([]);
    }else if(type === "none"){
      setSelectedServices([]);
      setExServices([]);
    }
  };

  const onChangeAccessType = (e) => {
    const value = e.target.value;
    setGcAccessType(value);
    setAccessOnAccessType(value);
  };

  const onChangeServiceAccessType = (e) =>{
    const value = e.target.value;
    setServiceAccessType(value);
    setServicesAccessOnAccessType(value);

  }

  const toggleGcEnabled = (e) => {
    const value = e.target.checked;
    if (value) {
      setGcAccessType('selected');
      setSelectedGcs([...classesWithAllAccess]);
    } else {
      setGcAccessType('none');
      setSelectedGcs([]);
    }
    setExceptGcs([]);
    setGcEnabled(value);
  };

  const onToggleOneToOne = (e) =>{
    setSelectedServices([]);
    setExServices([]);
    toggleEnabled(e);
  }

  return (
    <div className="my-15 fmx-20 w-100">
      {/* --------- 1 - 1 calling setting -------- */}
      <div>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={<Typography variant="h3">Appointments</Typography>}
          className="mb-0 mb-15"
          checked={enabled}
          onChange={onToggleOneToOne}
        />
        <Grid container justify="space-between">
          <Grid item xs={12} sm={6} className="pl-sm-2">
            <FormTextField fullWidth label="Number of Appointments">
              <TextField
                required
                {...nProps}
                variant="outlined"
                disabled={!enabled}
                InputProps={{
                  classes: { root: 'medium pr-1', input: 'size_16_500 medium' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ToggleLabel disabled={!enabled} size="small" value={frequency} options={FT} onChange={setFreq} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormTextField>
          </Grid>
        </Grid>
        {enabled && (
          <div>
            <div className="info-box-blue p-15 mb-20">
              <Typography className="font_14_500">
              The clients on this payment plan can book appointments for the services specified below as long as their membership is active.
              </Typography>
            </div>
            <ServiceAccess
              accessType={service_access_type}
              setAccessType={onChangeServiceAccessType}
              values={service_access_type === 'selected' ? selectedServices : exceptServices}
              setValues={handleServiceValues}
              memoizeGcs={memoizeGcs}
              disabled={!enabled}
            />
          </div>
        )}
      </div>
      {/*--------- Group Class Access ------------*/}
      {isGroupClass && (
        <div>
          <Divider className="mb-20" />
          <FormControlLabel
            control={<Switch color="primary" />}
            label={<Typography variant="h3">Group Classes</Typography>}
            className="mb-0 mb-15"
            checked={gc_enabled}
            onChange={toggleGcEnabled}
          />
          <div className="info-box-blue p-15 mb-20">
            <Typography className="font_14_500">
              The clients on this payment plan can book any of the specified group classes as long as their membership is
              active.
            </Typography>
          </div>
          <GcAccess
            accessType={gc_access_type}
            setAccessType={onChangeAccessType}
            values={gc_access_type === 'selected' ? selectedGcs : exceptGcs}
            setValues={handleSetValues}
            memoizeGcs={memoizeGcs}
            disabled={!gc_enabled}
          />
        </div>
      )}
    </div>
  );
};
export default RegularCalling;

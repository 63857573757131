import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Card, CardContent, Divider, Typography, makeStyles } from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import moment from 'moment';
import _ from 'lodash';
import { ddt2str } from 'fitbud/views/plan/tabs/DetailsTab';
import { durationInTypes } from 'fitbud/views/schedulePlanner/helper';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 15,
  },
  cardContent: {
    paddingLeft: '15px',
    paddingRight: 15,
    paddingTop: 20,
    paddingBottom: '0px !important',
  },
}));

const PURCHASE_TYPE = {
  MEMBERSHIP: 'membership',
  SESSION: 'sessions',
};

const PurchaseCard = (props) => {
  const { data, allowEdit, openEdit, onDelete, userTz } = props;
  const classes = useStyles();
  const accessType = _.get(data, 'accessType');
  const isMemberShip = accessType === PURCHASE_TYPE.MEMBERSHIP;
  const isSessionPack = accessType === PURCHASE_TYPE.SESSION;

  const edit = useMemo(() => {
    return !!allowEdit;
  }, [allowEdit, data]);

  const getTopLeft = () => {
    if (isMemberShip) return 'MEMBERSHIP PLAN';
    else if (isSessionPack) return 'SESSIONS PACK';
    return 'MEMBERSHIP PLAN';
  };
  const getTopRight = () => {
    if (isMemberShip) {
      if (data?.mode === 'one_time') {
        return moment(data.dateFirst).tz(userTz).format('DD MMM') + ' - ' + moment(data.dateExpiry).tz(userTz).format('DD MMM YYYY');
      }
    } else if (isSessionPack) {
      return 'Validity - ' + moment(data.dateExpiry).tz(userTz).format('DD MMM YYYY');
    }
    return 'Lorem Ipsum';
  };
  const getBottomLeft = () => {
    if (isMemberShip) {
      return _.get(data, 'pack.title');
    } else if (isSessionPack) {
      return `${_.get(data, 'accessValueOrig')} Sessions`;
    }
    return 'Lorem Ipsum ';
  };
  const getBottomRight = () => {
    if (isMemberShip) {
      if (data?.mode === 'one_time') {
        const _d = durationInTypes(data.accessValue, data.durationType);
        return ddt2str(_d, data.durationType);
      }
    } else if (isSessionPack) {
      return (
        <span>
          <span>{_.get(data, 'accessValueOrig') - _.get(data, 'accessValue')}</span> /{' '}
          <span className='font_15_500'>{_.get(data, 'accessValueOrig')}</span>
        </span>
      );
    }
    return 'Lorem Ipsum';
  };
  return (
    <Card elevation={1} className={clsx(classes.root, 'mb-15')}>
      <CardContent className={classes.cardContent}>
        <div className="d-flex mb-10 justify-content-between">
          <Typography color="primary" className="font_13_600">
            {getTopLeft()}
          </Typography>
          <Typography className="text-dark-gray">{getTopRight()}</Typography>
        </div>
        <div className={clsx('d-flex justify-content-between', !edit ? 'pb-20' : 'pb-15')}>
          <Typography className="font_15_600 text-truncate">{getBottomLeft()}</Typography>
          <Typography className="font_15_600 flex shrink-0 ml-10">{getBottomRight()}</Typography>
        </div>
        {edit && (
          <div>
            <Divider />
            <div className="d-flex py-6">
              <ClrdButton color="primary" className="flex-grow-1" title="Edit" onClick={openEdit} />
              <div>
                <Divider orientation="vertical" />
              </div>
              <ClrdButton color="primary" className="flex-grow-1" title="Delete" onClick={onDelete} />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
export default PurchaseCard;

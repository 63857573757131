import React from 'react';
import _ from 'lodash';
import AddOnCalling from './addOnCalling';
import RegularCalling from './regularCalling';

const VideoCallEditor = (props) => {
  const { data = {} } = props;
  const type = data.type;
  const isAddOn = type === 'add_on';
  const isMembershipAddOn=_.get(data, "type") === "add_on" && _.get(data, "add_on_type") === "membership";
  return (isAddOn && !isMembershipAddOn)? <AddOnCalling {...props} /> : <RegularCalling {...props} />;
};

export default VideoCallEditor;

import React, { useState, useContext, useMemo, Children } from "react";
import _ from "lodash";
import {
  Drawer,
  Divider,
  Collapse,
  List,
  MenuItem,
  ListItem,
  ListItemText,
  Menu,
  Hidden,
  IconButton,
  Tooltip,
  Badge,
  Button,
  makeStyles,
  Box,
  Avatar,
  Typography,
} from "@material-ui/core";
import {ChatBubbleIcon} from "fitbud/icons/sideMenu/chatBubble";
import { ExpandIcon,CompressIcon } from "fitbud/icons/sideMenu";
import clsx from "clsx";
import { withRouter, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { NavContext } from "fitbud/providers/appNav";
import NavLink from "fitbud/components/BtnNavLink";
import appRdxFns from "fitbud/redux/app";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { RoleContext } from "fitbud/providers/roleProvider";
import { AnalyticsContext } from "fitbud/providers/analytics";
import { bffLogout } from "fitbud/api";
import Logo from "fitbud/components/logo";
import { DrawerWidth, DrawerWidthMinified } from "fitbud/utils/constants";
import menuConf from "./menuConf";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import homeRdxFns from "fitbud/redux/home";
import selectedScheduleRdxFns from "fitbud/redux/selectedSchedule";
import { openIntercom } from "fitbud/utils/intercomm";
import PersonIcon from '@material-ui/icons/Person';
import { ACL_FEATURES_KEY, useAcl } from 'fitbud/providers/acl-provider';


const Talk2Us = (props) => {
  const { minified, onClick,className } = props;
  const handler = () => {
    if (onClick) onClick();
    openIntercom();
  };
  if (minified)
    return (
      <IconButton className={clsx('text-white',className)} onClick={handler}>
        <ChatBubbleIcon/>
      </IconButton>
    );
  else
    return (
      <Button className={clsx('text-white rounded-pill fpy-10 fpx-20',className)} variant="outlined" onClick={handler}>
        <ChatBubbleIcon className='fmr-10'/>
        <Typography variant="caption">Chat with us</Typography>
      </Button>
    );
}

const AdminComps = ({ companies, ...rest }) => {
  if (!companies || !companies.length) return <ListItem {...rest}>
    <ListItemText primary='No Company selected' />
  </ListItem>;
  return (<>{
    companies.map(c => <ListItem
      {...rest} key={c.id} data-cid={c.id}>
      <ListItemText primary={c.name} secondary='Currently selected' />
    </ListItem>)
  }</>);
}

const Comps = withRouter(props => {
  const { onClose, location, history, match, ...rest } = props;
  const { isAdmin, onlyOps, companies, select, cid } = useContext(FirebaseAuthContext);
  const selectCo = e => {
    const cid = e.target.dataset.cid;
    if (!cid) {
      onClose();
      return;
    }
    select(cid);
    onClose();
  };
  if (isAdmin) {
    return (
      <>
        <AdminComps {...rest} companies={companies}/>
        <Divider />
        <MenuItem
          {...rest}
          key="select"
          onClick={onClose}
          component={Link}
          to="/companies"
          className="nolink"
        >
          All Companies
        </MenuItem>
      </>
    );
  } else {
    return (
      <>
      {companies.length > 1 && companies.map(c => (
          <MenuItem
            {...rest}
            key={c.id}
            data-cid={c.id}
            onClick={selectCo}
            selected={c.id === cid}
          >
            {c.name}
          </MenuItem>
        ))}
        {onlyOps && <MenuItem
          {...rest}
          key="select"
          onClick={onClose}
          component={Link}
          to="/companies/new"
          className="nolink">+ Add Company</MenuItem>}
        {(companies.length > 1 || onlyOps) && <Divider />}
      </>
    );
  }
});

const ProfileMenu = connect(undefined, d => {
  const { showLoader } = appRdxFns(d);
  return { showLoader };
})(props => {
  const { onClose, showLoader, ...rest } = props;
  const logout = () => {
    showLoader();
    bffLogout();
  };
  return (<>
    <Menu {...rest} onClose={onClose}>
      <Comps onClose={onClose} />
      <MenuItem
        component={Link}
        to="#change-password"
        className="nolink"
        onClick={onClose}
      >
        Change Password
      </MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  </>);
});

const BottomOpts = React.forwardRef((props, _) => {
  const { profileClick, minified, toggle,onClose } = props;

  const { event } = useContext(AnalyticsContext);
  const toggleRefer = () => {
    event('referral_click');
  };
  return (
    <div className='profile'>
      {!minified && 
        <div className='dividerTop pt-15'>
          <div className='position-relative'>
            <Link to='/referral-program' className="text-white text-decoration-none">
              <Button className={clsx("w-100 py-10 px-20 mb-15 rounded-pill text-white talk2us")} variant="outlined"
               onClick={toggleRefer}>
                <i className="fas fa-gift mr-2"/>
                <Typography variant="caption">Refer &amp; Earn</Typography>
              </Button>
            </Link>
          </div>
          <div className='d-flex align-items-center'>
            <Tooltip title="Minimize Menu" placement="top">
              <IconButton className="text-muted compressIcon" onClick={toggle}>
                <CompressIcon/>
              </IconButton>
            </Tooltip>
              <Avatar className="avatar cursor-pointer" onClick={profileClick}>
                <PersonIcon/>
                {/* <Typography className="font_15_600">{getUiAvatar(name).toUpperCase()}</Typography> */}
              </Avatar>
            <Talk2Us minified={false} onClick={onClose} className={'talk2us'} />
          </div>
        </div>
      }
     
       {minified && <div className="dividerTop pt-15 d-flex flex-column justify-content-center align-items-center">
       <Talk2Us minified={true} onClick={onClose} className="talk2us fmb-15"/>
       <Tooltip title="Expand Menu" placement="top">
        <IconButton className="text-muted expandIcon" onClick={toggle}>
          <ExpandIcon/>
        </IconButton>
       </Tooltip>
       </div>}
    </div>
  );
});

const styles = makeStyles(theme => ({
  container: {
    position: "relative",
    [theme.breakpoints.up("md")]: { flexShrink: 0 },
    "& .collapser": {
      position: "absolute",
      width: 24,
      height: "auto",
      right: -12,
      top: 12,
      cursor: "pointer",
      fontSize: 24,
      color: "#cccccc",
      zIndex: theme.zIndex.drawer + 2,
      textAlign: "left !important",
      [theme.breakpoints.down("sm")]: { display: "none" }
    },
  },
  drawer: {
    "& .logo": {
      position: "fixed",
      padding:"0px 20px",
      height: 60,
      width: DrawerWidth,
      background: logoBGClr,
      borderBottom: `1px solid ${dividerClr}`,
      zIndex: theme.zIndex.drawer + 1
    },
    "& .paper": {
      overflowX: "hidden !important",
      overflowY: "scroll !important",
      borderWidth: 0,
      background: drawerBGClr,
      width: DrawerWidth
    },
    "& .arr": { color: dividerClr },
    "& hr": { backgroundColor: `${dividerClr} !important` },
    "& .item": {
      color: inactiveClr,
      "& .non-arr svg": {
        fill:'transparent',
        width:"24px",
        height:"24px"
      },
      '& .item-text':{
        // marginLeft:"20px"
      },
      "&:hover": {
        color: activeClr,
        backgroundColor: expandedClr,
        "& .arr": { color: activeClr },
        '& svg path, ellipse':{
          stroke:activeClr,
          color:activeClr,
        },
      },
      "& not(.active):hover":{
        backgroundColor: 'red',
      },
      "&.part-active": {
        color: activeClr ,
        backgroundColor:expandedClr,
        '& svg path, ellipse':{
          stroke:activeClr,
          color:activeClr,
        },
      },
      "&.active": {
        color:activeClr,
        backgroundColor:(props)=>{
          if(!props.minified && !!props.hasChildren){
            return 'transparent';
          }
          else return `${activeBGClr}!important`;
        },
        '& svg path, ellipse':{
          stroke:activeClr,
          color:activeClr
        },
      },
      "&.l1": { backgroundColor: expandedClr, height:s2 },
      "&.l0": { height: s1, position: "relative" },
      "& .submenu": {
        backgroundColor: expandedClr,
        paddingBottom: "8px",
        minWidth: "200px",
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        boxShadow: theme.shadows[10],
        position: "absolute",
        top: 0,
        left: DrawerWidthMinified,
        display: "none",
        overflow: "hidden",
        "& .marker": {
          position: "absolute",
          top: 0,
          left: -10,
          width: 11,
          height: s1,
          backgroundColor: expandedClr
        }
      },
      "&:hover .submenu": { display: "block" },
      "& .item-text span": {
        fontSize: '14px',
        fontWeight: 500,
        whiteSpace: "nowrap !important"
      },
      "&.l0 .item-text span": { fontSize: 15 },
      "&.l1 .item-text span": { fontSize: '14px' },
      "&.active .item-text span": { fontWeight: 600 }
    },
    "& .profile": {
      position: "sticky",
      bottom: 0,
      padding: "0 12px 15px 12px",
      width: DrawerWidth,
      background:profileBGClr,
      '& .compressIcon':{
        marginLeft:"-10px",
        '& svg':{
          height:"12px",
          width:"12px"
        }
      },
      '& .expandIcon':{
        '& svg':{
          height:"12px",
          width:"12px"
        }
      },
      '& .avatar':{
        border: "1px solid rgba(255, 255, 255, 0.1)",
        background: "#292D42",
        height:"36px",
        width:"36px",
        marginRight:"15px"
      },
      '& .talk2us':{
        border: "1px solid rgba(255, 255, 255, 0.1)",
        background: "#292D42",
        whiteSpace:"nowrap",
        '& svg':{
          height:"14px",
          width:"14px"
        }
      }
    },
    // CSS changes when drawer is minified
    "&.minified": {
      "& .logo": { width: DrawerWidthMinified },
      "& .profile": { width: DrawerWidthMinified },
      "& .paper": {
        width: DrawerWidthMinified,
        overflowX: "visible !important",
        overflowY: "visible !important"
      },
      "& .part-active": { 
        color: activeClr,
        backgroundColor:`${activeBGClr}!important`,
        '& svg path, ellipse':{
          stroke:activeClr,
          color:activeClr,
        },
        '& .marker':{
          backgroundColor:`${activeBGClr}!important`
        }
       },
      "& .item.l0 .item-icon": { fontSize: "1.25rem" },
      "& .item.l0": {
        padding: 0,
        justifyContent: "center",
        '& .divider':{
          borderColor:"#ffffff14"
        }
      },
      "& .collapser": {
        textAlign: "right !important",
        color: activeClr
      },
      "& .item.l0.expandable:hover>.non-arr>span>span.badge": {
        display: 'none',
      },
    },
    "& .dividerTop":{
      borderTop: `1px solid ${dividerClr}`,
    },
  },
}));

const useButtonStyles = makeStyles(()=>({
  newButton: {
    height: 30,
    width: 160,
    color: "white",
    border: "1px solid rgba(255, 255, 255, 0.3)"
  },
  newButtonLabel: {
    fontSize: 14, fontWeight: 500 
  },
  activeBorder:{
    '&:hover':{
      border:`1px solid ${activeClr}`
    }
  }
}));

const useBadgeStyles = makeStyles(()=>({
  badgeSmall: {
    height: '8px',
    width: '8px !important',
    minWidth: '8px !important',
    padding: '0 0 1px 0 !important',
    textAlign: 'center',
  },
}));

const NON_EXPANDING_URLS = ['/clients/chats', '/clients/checkins']
const ROUTE_MATCH_NON_EXACT=['/clients/chats', '/clients/checkins', '/config', '/plans', '/explore/resources','/challenges']

const ItemL0=withRouter(props => {
    const {
      location,
      history,
      match,
      to,
      minified,
      children,
      level = 0,
      hideIcon = false,
      badgeContent = null,
      label = false,
      showSubmenuHeading = true,
      fkey,
      ...rest
    } = props;
    const classes = useBadgeStyles(props);

    const { checkShowFeature, isNewFeatures } = useAcl();
    const showIsNew = isNewFeatures([fkey, ...Children.toArray(children)?.map(x => x.props.fkey)]);
    const item = menuConf[to];
    if (!item && !label) return null;
    const icon = item && item.icon;
    const text = label || item.label;
    if (minified && level===0 && !icon) return null;
    const minned = minified && level === 0;
    const hasChildren = React.Children.count(children) > 0;
    const hasMenu = minned && hasChildren;
    let expanded = hasChildren && location.pathname.startsWith(to) && !NON_EXPANDING_URLS.some(url => location.pathname.startsWith(url));
    const noLink = to.startsWith('call-');
    const isExternal = to.startsWith('http');
    const linkProps = hasMenu || noLink ? {} : (isExternal ? { href: to, target:"_blank" }
      : { to: to,
         exact: (level===1 || ROUTE_MATCH_NON_EXACT.some(url=>url===to))?false:true,
         strict: true });

    if(!checkShowFeature(fkey)) return <></>;

    return (
      <>
        <ListItem
          key={to}
          button
          component={hasMenu || noLink ? "div" : (isExternal ? "a" : NavLink)}
          {...linkProps}
          {...rest}
          className={clsx("item",`l${level}`, hasChildren && "expandable", expanded && "part-active")}
          classes={{
            gutters:"fpx-20"
          }}
        >
          <div className="non-arr">
            {(level ===0 || !minified) && !hideIcon  && icon}
            {minned && !!badgeContent && (<Badge badgeContent={badgeContent} classes={{ badge: 'badge bg-primary text-white' }} style={{right:-3,top:-10}} max={9999} />)}
          </div>
          {!minned && (
            <ListItemText
              primary={text}
              color="inherit"
              classes={{primary:"text-truncate"}}
              className={clsx("item-text",level===0 && "fml-20", (level>0 && !minified) && (item && item.show ? "fml-20": "fml-44"))}
            />
          )}
          {showIsNew && (<Badge badgeContent={level === 0 ? 'NEW' : '•'} classes={{ badge: `badge bg-primary text-white ${level ? classes.badgeSmall : ''}` }} style={{right: level ? '4px' : '20px', zIndex: 0}} />)}
          {!minned && (!hasChildren || !expanded) && (
            <Badge
              badgeContent={badgeContent}
              children={""}
              style={{ right: "10px" }}
              classes={{ badge: 'badge bg-primary text-white' }}
              max={9999}
            />
          )}
          {!minned && !badgeContent && hasChildren && !expanded && (
            <ExpandMore className="arr open" />
          )}
          {minned && hasChildren  && (
            <div className="submenu">
              <div className="marker" />
              <List disablePadding component="nav">
                {showSubmenuHeading && (
                  <ListItem
                    divider
                    classes={{gutters:"fpx-20", divider:'divider'}}
                    style={{cursor:"auto", height: s1}}
                  >
                    <ListItemText>
                      {text}
                    </ListItemText>
                  </ListItem>
                )}
                {children}
              </List>
            </div>
          )}
        </ListItem>
        <Collapse in={expanded && !minified && hasChildren}>
          {children}
          <div style={{ height: 8, backgroundColor: expandedClr }} />
        </Collapse>
      </>
    );
  }
)

const ItemL1 = props => <ItemL0 {...props} level={1} />;

const DrawerItems = props => {
  const {
    minified,
    onClose,
    newMessages,
    newCheckins,
    clearSelectedSchedule,
    groupChats,
  } = props;
  const { isAdmin, cid, comp } = useContext(FirebaseAuthContext);
  const { isTrainerRole, isOwner, catalogAdmin } = useContext(RoleContext);
  const company = comp ? comp.data() : {};
  const showLeads =_.get(company, "features.signup_ios") || _.get(company, "features.signup_android");
  const isGroupChat = Boolean(_.get(company, "features.group_chat"));
  const buttonClasses = useButtonStyles()
  const [showExplore, exploreTabs, forYouRails] = useMemo(() => {
    let out = [{label: 'Fitness', to: '/explore/workout'}, {label: 'Nutrition', to: '/explore/nutrition'}, {label: 'Resources', to: '/explore/resources', isResource:true}];
    let out2 = [];
    if (company.explore_config)
      out = _.chain(company.explore_config).toPairs().orderBy(x => x[1].position)
        .map(x => ({ label: x[1].title, to: `/explore/${x[0]}`, isResource : x[0] === 'resources' })).value();
    if (company.dash_config && company.dash_config.sections)
      out2 = _.filter(company.dash_config.sections, x => (x.type === 'foryou'))
        .map(x => ({ label: x.title, to: `/curated/${x.key}` }));
    return [true, out, out2];
  }, [company]);
  const newGroupThreads = Object.values(groupChats || {}).filter((s) => !!s).length;
  let {location,push} = useHistory();
  const addNew = () => {
    clearSelectedSchedule && clearSelectedSchedule();
    if(location.pathname.includes('/client')){
        const pathName = location.pathname.split('/').slice(0,3).join('/')
        push(`${pathName}/new`)
    }
    else{
      push("/clients/plansToAssign/new")
    }
    
  };
  if (isAdmin && cid === "fitbud") {
    return (
      <>
        <ItemL0 minified={minified} onClick={onClose} to="/staff" />
        <ItemL0 minified={minified} onClick={onClose} to="/companies" />
      </>
    );
  } else {
    return (
      <>
        <ItemL0 minified={minified} onClick={onClose} to="/home" />
        <ItemL0 minified={minified} onClick={onClose} to="/clients">
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/browse"
          />
          {showLeads && <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/leads"
          />}
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/pendingSignIn"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/plansToAssign"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/new"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/active"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/clients/inactive"
          />
          <div className="d-flex justify-content-center align-items-center item l1">
            <Button 
              className={clsx(buttonClasses.newButton,buttonClasses.activeBorder)}
              classes={{
                label: buttonClasses.newButtonLabel
              }}
              variant="outlined"
              onClick={addNew} >
              + Add New Client 
            </Button>
          </div>
        </ItemL0>
        <ItemL0 minified={minified} onClick={onClose} to="/messages" badgeContent={newMessages + (isGroupChat ? newGroupThreads : 0) }>
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/messages/chats"
            badgeContent={newMessages}
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/messages/group-chats"
            badgeContent={newGroupThreads}
            fkey="group_chat"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/messages/broadcasts"
            fkey="broadcasts"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/messages/in-app-notifications"
            fkey="in_app_notifications"
          />
        </ItemL0>
        <ItemL0
          minified={minified}
          onClick={onClose}
          to="/clients/checkins"
          badgeContent={newCheckins}
        />
        <ItemL0 minified={minified} onClick={onClose} to="/fitness">
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/fitness/exercises"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/fitness/workouts"
          />
          <ItemL1 minified={minified} onClick={onClose} to="/fitness/cardio" />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/fitness/schedules"
          />
        </ItemL0>
        <ItemL0 minified={minified} onClick={onClose} to="/nutrition">
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/foods"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/recipes"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/daily-meals"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/supplements"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/daily-supplements"
          />
          <ItemL1
            minified={minified}
            onClick={onClose}
            to="/nutrition/schedules"
          />
        </ItemL0>
        {!showExplore && <ItemL0 minified={minified} onClick={onClose} to={"/explore/resources"} fkey="resources" />}
        {showExplore && catalogAdmin && <ItemL0 minified={minified} onClick={onClose} to="/explore" fkey="explore">
          {exploreTabs.map(x => <ItemL1 key={x.to} minified={minified} onClick={onClose} to={x.to} label={x.label} fkey={x.isResource ? "resources" :"explore"} hideIcon />)}
          <ItemL1 minified={minified} fkey={"explore"} onClick={onClose} to="/explore/configuration" />
        </ItemL0>}
        {showExplore && catalogAdmin && Boolean(forYouRails.length) && <ItemL0 minified={minified} onClick={onClose} to="/curated">
          {forYouRails.map(x => <ItemL1 key={x.to} minified={minified} onClick={onClose} to={x.to} label={x.label} hideIcon />)}
        </ItemL0>}
        <ItemL0 minified={minified} onClick={onClose} to="/challenges" fkey="challenges"/>
        <ItemL0 minified={minified} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} onClick={onClose}  to="/live" >
            <ItemL1 minified={minified} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} onClick={onClose} to="/live/calendar" />
            <ItemL1 minified={minified} fkey={ACL_FEATURES_KEY.GROUP_CLASS} onClick={onClose} to="/live/group-classes" />
             {/* TODO : fkey for acl. now working with video features key. */}
            <ItemL1 minified={minified} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} onClick={onClose} to="/live/services" />
            {!!isOwner && <ItemL1 minified={minified} onClick={onClose} fkey={ACL_FEATURES_KEY.VIDEO_CALLING} to="/live/configuration" />}
        </ItemL0>
        {!isTrainerRole && <ItemL0 minified={minified} onClick={onClose} fkey={ACL_FEATURES_KEY.TRAINERS} to="/trainers" />}
        {!isTrainerRole && <ItemL0 minified={minified} onClick={onClose} to="/plans" />}
        {isOwner && <ItemL0 minified={minified} onClick={onClose} to="/client-app/theme" />}
        {!minified && (
          <>
            {!isTrainerRole && <ItemL0 minified={minified} onClick={onClose} to="/website" fkey="website" />}
            <ItemL0 minified={minified} onClick={onClose} to="/config" />
            <ItemL0 minified={minified} onClick={onClose} to="https://help.fitbudd.com/en/" />
          </>
        )}
        {minified && (
          <ItemL0 minified onClick={onClose} to="/more" showSubmenuHeading={false}>
            {!isTrainerRole && <ItemL1 minified={minified} onClick={onClose} to="/website" fkey="website" />}
            <ItemL1 minified={minified} onClick={onClose} to="/config" />
            <ItemL1 minified={minified} onClick={onClose} to="https://help.fitbudd.com/en/" />
          </ItemL0>
        )}
      </>
    );
    //<ItemL0 minified={minified} onClick={onClose} to="/reports" />
    //<Divider />
  }
};

const DrawerContent = props => {
  const { drawerState, onClose, toggle, ...rest } = props;
  const { minified } = drawerState;
  const [profileEl, setProfileEl] = useState(null);
  const { userProfile: user, cid } = useContext(FirebaseAuthContext);
  const toggleProfileMenu = e =>
    setProfileEl(prev => (!!prev ? null : e.currentTarget));
  return (
    <div className="d-flex flex-column min-vh-100 d-print-none">
      <Box display="flex" alignItems="center" justifyContent={"start"} className="logo">
        <Link to='/'>
          <Logo
            height={32}
            variant={minified ? "icon" : ""}
            color="fancy-white"
            fullWidth
          />
        </Link>
      </Box>
      <div style={{ opacity: 0, minHeight: 60 }} />
      <List disablePadding component="nav" className="flex-grow-1">
        <DrawerItems minified={minified} onClose={onClose} {...rest} />
      </List>

      <BottomOpts
        minified={minified}
        user={user} cid={cid}
        toggle={toggle}
        onClose={onClose}
        profileClick={toggleProfileMenu}
        {...user}
      />
      <ProfileMenu
        anchorEl={profileEl}
        open={!!profileEl}
        onClose={toggleProfileMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        marginThreshold={0}
        transitionDuration={0}
      />
    </div>
  );
};

const AppDrawer = props => {
  const classes=styles()
  const { drawerState, closeDrawer, closeIfMobile, toggleDrawer } = useContext(
    NavContext
  );
  const { minified } = drawerState;
  const toggle = e => {
    toggleDrawer();
    e.stopPropagation();
    e.preventDefault();
  };
  if (drawerState.hidden) return null;
  const widthProps = { style: { width: drawerState.drawerWidth } };
  return (
    <nav className={`${classes.container} ${minified ? "minified" : ""} d-print-none`}>
      <Hidden smDown>
        <Drawer
          open={drawerState.expanded}
          onClose={closeDrawer}
          classes={{ paper: "paper" }}
          variant="permanent"
          {...widthProps}
          className={`${classes.drawer} ${minified ? "minified" : ""}`}
        >
          <DrawerContent
            onClose={closeIfMobile}
            toggle={toggle}
            drawerState={drawerState}
            {...props}
          />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        {!!drawerState.expanded && <Drawer
          open={drawerState.expanded}
          onClose={closeDrawer}
          classes={{ paper: "paper" }}
          variant="temporary"
          {...widthProps}
          className={classes.drawer}
        >
          <DrawerContent
            onClose={closeIfMobile}
            toggle={toggle}
            drawerState={drawerState}
            {...props}
          />
        </Drawer>}
      </Hidden>
    </nav>
  );
};
const drawerBGClr = "#212435";
const activeBGClr = "#30344D";
const expandedClr="#282B40";
const logoBGClr = "#212435";
const profileBGClr="#212435";
const dividerClr = "rgba(255,255,255,0.12)";
const inactiveClr = "#8F969F";
const activeClr = "#FFFFFF";
const s1 = 48;
const s2 = 44;
const mapStateToProps = (s) => ({ ...s.home });
const mapDispatchToProps = d => { 
  let {clear : clearSelectedSchedule} = selectedScheduleRdxFns(d)
  return { ...homeRdxFns(d), clearSelectedSchedule }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppDrawer);
export { ProfileMenu };

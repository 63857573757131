import React, { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { servicesRdxFn } from 'fitbud/redux/exports';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { snapshotDocsArrayToDataArray } from 'fitbud/utils/helpers';

export const SERVICE_CONTEXT = React.createContext({});

export const ServiceProvider = (props) => {
  const dispatch = useDispatch();
  const { cid } = useContext(FirebaseAuthContext);
  const servicesRdxOptions = servicesRdxFn(dispatch);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { docs = [] } = useSelector((s) => s.services);

  const fetchServices = async (refresh) => {
    //either refreshing or loading for first time.
    const ref = firebase.firestore().collection(`/companies/${cid}/services`).orderBy("index");
    if (!refresh && (docs || []).length) return; //if loading and data presents then do nothing.
    const _loader = refresh ? setRefreshing : setLoading;
    _loader(true);
    const snap = await ref.get();
    let services = [];
    if (!snap.size) services = [];
    else {
      const data = snapshotDocsArrayToDataArray(snap.docs);
      services = data;
    }
    servicesRdxOptions.set(services, 0, true);
    _loader(false);
  };

  const loadServices = async () => await fetchServices(false); //loading
  const refreshServices = async () => await fetchServices(true); //refreshing.

  const getServiceById = useCallback((id) =>{
    if(!id || !docs || !docs.length) return undefined;
    const find = _.find(docs,['_id', id]);
    return find;
  },[docs])

  const _value = useMemo(() => {
    return {
      loading,
      refreshing,
      services: docs,
      loadServices,
      refreshServices,
      getServiceById
    };
  }, [loading, docs, refreshing]);
  return <SERVICE_CONTEXT.Provider value={_value}>{props.children}</SERVICE_CONTEXT.Provider>;
};

export const useServices = () => React.useContext(SERVICE_CONTEXT);





/*

 1 . slot conflicts.
 2. default value for service and location

*/
import React, { useState, useMemo } from 'react';
import { Select, MenuItem, OutlinedInput, Grid, Typography, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import ServiceSelector from 'fitbud/components/catalogSelector/servicesSelector';
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';
import Add from '@material-ui/icons/Add';


const SERVICE_ACCESS_TYPES = [
  { label: 'None', value: 'none' },
  { label: 'Selected Services', value: 'selected' },
  { label: 'All Services', value: 'all' },
  { label: 'All Services Except', value: 'except_all' },
];

const ServiceAccess = (props) => {
  const {
    values,
    setValues,
    classes = {},
    accessType,
    setAccessType,
    memoizeGcs,
    allowNull = false,
    disabled,
    withCalls = false,
    ACCESS_OPTIONS,
  } = props;
  const [openServiceSelector, setGcSelector] = useState(false);
  const toggleGcSelector = (value = false) => setGcSelector(value);
  const selected = useMemo(() => {
    return values.map((id) => ({ _id: id }));
  }, [values]);
  const ACCESS_TYPE = useMemo(() => {
    if(!!ACCESS_OPTIONS) return ACCESS_OPTIONS;
    return SERVICE_ACCESS_TYPES;
  }, [SERVICE_ACCESS_TYPES, allowNull, ACCESS_OPTIONS]);

  const gcChipData = useMemo(() => {
    const out = [];
    values.forEach((classId) => {
      const classDetail = _.get(memoizeGcs, `current.${classId}.data`, {});
      if(!_.isEmpty(classDetail)){
        out.push({
          label: classDetail.title,
          value: classId,
          onDelete: () => removeAccess(classId),
        });
      }
    });

    out.push({
      label: (
        <Typography className={clsx('text-primary font_13_600')}>
          <Add style={{ width: '16px', marginTop: '-2px' }} /> Add More
        </Typography>
      ),
      value: 'add_more',
      onClick: disabled ? () => {} : () => toggleGcSelector(true),
    });
    return out;
  }, [values, disabled]);

  const removeAccess = (gcId) => {
    const index = _.findIndex(values, (id) => id === gcId);
    if (index >= 0) {
      const out = [...values];
      out.splice(index, 1);
      setValues(out);
    }
  };

  const addSelectedGcs = (selectedValues) => {
    const gcIds = selectedValues.map((gc) => gc._id);
    const gcDetails = selectedValues.reduce((acc, current) => {
      acc[current._id] = { _id: current._id, data: current.data };
      return acc;
    }, {});
    memoizeGcs.current = _.merge(memoizeGcs.current, gcDetails);
    setValues(gcIds);
    toggleGcSelector();
  };

  return (
    <div className={clsx(classes.root, 'w-100')}>
      <Grid container>
        <Grid item xs={12} md={6} className={clsx(withCalls && "pr-20")}>
          <FormTextField fullWidth label="Access Control">
            <Select
              disabled={disabled}
              required
              variant="outlined"
              name="gc_access_type"
              fullWidth
              value={accessType}
              className={clsx('mb-0')}
              onChange={setAccessType}
              input={<OutlinedInput classes={{ root: 'medium', input: 'size_16_500 select-medium' }} />}>
              {ACCESS_TYPE &&
                ACCESS_TYPE.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={item.value + index}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormTextField>
        </Grid>
        {(accessType === 'selected' || accessType === 'except_all') && (
          <Grid item xs={12}>
            <ChipGroups
              noBorder
              fields={gcChipData}
              componentState={[]}
              isMulti={true}
              classes={{ 
                label: 'font_13_600 text-dark-grey',
            }}
            />
          </Grid>
        )}
        {openServiceSelector && (
          <ServiceSelector
            handleAdd={addSelectedGcs}
            title="Select Services"
            isBack
            open
            selected={selected}
            hidePreSelected={selected.length}
            handleClose={() => toggleGcSelector(false)}
          />
        )}
      </Grid>
    </div>
  );
};

export default ServiceAccess;
